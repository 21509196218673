import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Campaign/Creation/CreationSlice";
import { useSelector } from "react-redux";

const useEarnings = ({ formik, setSelectedOption }) => {
  const { basicData } = useSelector((state) => state?.global);
  const { isEdit } = useSelector((state) => state.campaignCreate);
  const dispatch = useDispatch();

  const handleRadioChange = (event) => {
    const id = event.target.id;
    setSelectedOption(id);
    formik?.setFieldValue("earnings_type", id);
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };

  const handleNext = () => {
    dispatch(updateConfig((state) => (state.formActiveTab = "Tasks")));
  };

  return {
    isEdit,
    basicData,
    handleCloseModal,
    handleNext,
    handleRadioChange,
  };
};

export default useEarnings;
