import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../API";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "dec"
          }&items_per_page=${params?.items_per_page || "10"}&${getParams(
            "search"
          )}&${getParams("brand_id")}&${getParams("start_date")}&${getParams(
            "end_date"
          )}current_page=${params?.current_page|| ""}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "dec"
          }&${getParams("start_date")}&items_per_page=${
            params?.items_per_page || "10"
          }&${getParams("search")}current_page=${params?.current_page || ""}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const brand = createApi({
  reducerPath: "brandApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["brand", "brand_details", "brand_details_table"],
  endpoints: (builder) => ({
    getBrandData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/brand/list`,
      }),
      providesTags: ["brand"],
    }),
    getBrandDetailsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/brand/${values?.user_id}/view`,
      }),
      providesTags: ["brand_details"],
    }),
    getBrandDetailsCountData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/brand/summary-count`,
      }),
    }),
    getBrandDetailsTableData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/brand/collaboration`,
      }),
      providesTags: ["brand_details_table"],
    }),
    updateBrandTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/field-update`,
      }),
      invalidatesTags: ["brand"],
    }),
    UpdateBrandDetailsTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/field-update`,
      }),
      invalidatesTags: ["brand_details_table"],
    }),
    updateStatusData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/brand/status-change`,
      }),
    }),
  }),
});

export const {
  useGetBrandDataQuery,
  useGetBrandDetailsDataQuery,
  useUpdateStatusDataMutation,
  useGetBrandDetailsCountDataQuery,
  useGetBrandDetailsTableDataQuery,
  useUpdateBrandTableHeadDataMutation,
  useUpdateBrandDetailsTableHeadDataMutation,
} = brand;
