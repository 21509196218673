import {
  ModalLayout,
  NavGroup,
  SearchFilters,
  Table,
  DataContainer,
  SimpleReport,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import usePayout from "./usePayout";
import { Link } from "react-router-dom";
import Style from "./payout.module.scss";
import Declined from "./Declined";
import { Pagination } from "../../Global/Pagination";

const Payout = () => {
  const {
    paginationOptions,
    paymentsList,
    isFetching,
    isLoading,
    tableFields,
    showEditModal,
    paymentsState,
    tabNavs,
    paymentReportDetails,
    paymentReportFetching,
    handleSearch,
    handleSort,
    handlePageSize,
    handleExportTable,
    handlePagination,
    handleEditColumnsClick,
    handleClearClick,
    getRow,
    updatePaymentsFields,
    refetch,
    handleClosedeclineModal,
    paymentReportRefetch
  } = usePayout();
  return (
    <div className="col-auto pro-pt-3 pro-pb-6 pro-m-4 pro-mx-5">
      <DataContainer>
        {paymentReportFetching ? (
          <p>Loading</p>
        ) : (
          <SimpleReport
            data={paymentReportDetails}
            propStyle={{ item: Style.report_root }}
          />
        )}
      </DataContainer>
      <div className={`${Style.main_container}`}>
        <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
      </div>
      <SearchFilters
        onSearchInput={handleSearch}
        showActions={true}
        handleActionClick={handleEditColumnsClick}
        loading={isLoading}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters
        handleClear={handleClearClick}
        searchInputProps={{ value: paymentsState?.search }}
        filterChildren={
          <>
            {paymentsList?.data?.permissions?.can_export && (
              <div className="col-auto pro-ms-auto">
                <button
                  className={"pro-btn pro-items-center pro-btn-outline "}
                  onClick={handleExportTable}
                >
                  <span className="material-symbols-outlined x4">
                    outgoing_mail
                  </span>
                  <span>Export Table</span>
                </button>
              </div>
            )}
          </>
        }
      />
      {paymentsList?.data?.data?.length > 0 ? (
        <>
          <Table
            multiSelect={false}
            data={paymentsList?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            clear={paymentsState.clearSelection}
            getRow={getRow}
            loading={isFetching}
            perpage={paymentsState?.currentPageSize}
            editable={paymentsList?.data?.permissions?.can_update}
            deletable={false}
            assignable={false}
            showCheckBox={paymentsList?.data?.permissions?.can_update}
          />
          {paymentsList?.data?.data?.length > 0 && (
            <Pagination
              currentPage={paymentsState?.currentPage}
              totalPageCount={Math.ceil(
                paymentsList?.data?.total_count / paymentsState.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </>
      ) : (
        <EmptyData />
      )}
        <ModalLayout
          show={paymentsState?.declineStopModal}
          handleClose={handleClosedeclineModal}
          title={"Decline the Payment"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <div className="pro-m-5">
             <Declined paymentReportRefetch={paymentReportRefetch}/>
          </div>
        </ModalLayout>
      
      <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetchData={refetch}
            tableFields={paymentsList?.data?.fields}
            moduleId={paymentsList?.data?.table_id}
            updateData={updatePaymentsFields}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export default Payout;
