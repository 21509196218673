import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useFilterNotification from "./useFilterNotification";

const FilterNotification = ({ refetch }) => {
  const { formik, handleCloseModal, basicData } = useFilterNotification({
    refetch,
  });
  return (
    <div className="col">
      <div className="pro-mb-4">
        <label
          htmlFor="notification_type"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Notification type
        </label>
        <Select
          id="notification_type"
          placeholder={"Notification type"}
          className={`pro-input lg`}
          classNamePrefix="pro-input"
          name="notification_type"
          options={basicData?.notification_type ?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.notification_type}
          onBlur={formik.handleBlur("notification_type")}
          onChange={(value) =>
            formik.setFieldValue("notification_type", value ?? "")
          }
          menuPlacement="auto"
          isClearable
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto offcanvas-footer-sticky-btn`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="submit"
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterNotification;
