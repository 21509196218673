import {
  HeadingGroup,
  Permissions,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useManageRoles from "./useManageRole";
import Style from "./manageRoles.module.scss";

const ManageRole = () => {
  const {
    state,
    role,
    // update,
    name,
    userLevel,
    error,
    levelError,
    handleSave,
    handleNameChange,
    handleLevelChange,
    handlePermissionChange,
  } = useManageRoles();

  return (
    <>
      <HeadingGroup
        title={"Roles"}
        buttonTitle={"Save"}
        handleClick={handleSave}
        extraClassName={`pro-justify-between pro-pb-4 `}
      />
      <div className={`row`}>
        <div className={`col-6`}>
          <div className="input-wrap pro-mb-5">
            <label
              htmlFor="role_name_input"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Name
            </label>
            <input
              id="role_name_input"
              type="text"
              value={name}
              onChange={handleNameChange}
              className={`pro-input lg ${error && "error"}`}
            />
            {error && <span className="error-text">{error}</span>}
          </div>
        </div>
        <div className={`col-6`}>
          <div className="input-wrap pro-mb-5">
            <label
              htmlFor="role_level_input"
              className="pro-font-sm pro-mb-1 pro-fw-medium pro-d-flex pro-justify-center"
            >
              Level
              <span
                className="material-symbols-outlined "
                title="Roles with a lower level can control roles with higher level. Example, level 0 can control level 1"
              >
                info
              </span>
            </label>
            <input
              id="role_level_input"
              type="text"
              value={userLevel}
              onChange={handleLevelChange}
              className={`pro-input lg ${levelError && "error"}`}
            />
            {levelError && <span className="error-text">{levelError}</span>}
          </div>
        </div>
      </div>
      <div className="pro-pb-6">
        {(role ? state.rolePermission?.[role] : true) &&
          state?.allPermissions?.map?.((permission, index) => (
            <div className={Style.root} key={index}>
              <Permissions
                title={permission?.module_name}
                permissions={permission?.permissions}
                defaultCheckedList={
                  state.rolePermission?.[role]?.modules?.filter?.((m) => {
                    return m.module_id === permission._id;
                  })?.[0]?.permissions || []
                }
                key={index}
                uniqueID={"id"}
                handleChange={(item) => {
                  return handlePermissionChange(item, permission._id);
                }}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default ManageRole;
