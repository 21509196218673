import React from "react";
import CommonLayout from "../../Pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useBrands from "./useBrands";

const Brands = () => {
  const { drawerMenu } = useBrands();
  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Brands;
