import React from "react";
import CommonLayout from "../../Pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useCampaign from "./useCampain";

const Campain = () => {
  const { drawerMenu } = useCampaign();
  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Campain;
