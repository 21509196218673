import React from "react";
import Select from "react-select";
import useOverview from "./useOverview";
import { Input, Button } from "@wac-ui-dashboard/wac_component_library";

const OverviewTab = ({ formik, getFieldError, headerData }) => {
  const { basicData, isSocialConnectionEdit, handleCloseModal, handleNext } =
    useOverview();

  return (
    <div className="pro-d-flex pro-flex-column pro-h-100">
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="social_media"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Social Media
        </label>
        <Select
          id="social_media"
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.touched?.social_media &&
            formik.errors?.social_media &&
            "error"
          }`}
          classNamePrefix="pro-input"
          name="social_media"
          options={
            basicData?.social_media?.filter(
              (item) => !headerData?.some((v) => item?.name === v?.stream)
            ) || []
          }
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.social_media}
          onBlur={formik.handleBlur("social_media")}
          onChange={(value) =>
            formik.setFieldValue("social_media", value ?? "")
          }
          menuPlacement="auto"
          isClearable
          isDisabled={isSocialConnectionEdit}
        />
        {formik.touched?.social_media && formik.errors?.social_media && (
          <span className="error-text">{formik.errors?.social_media}</span>
        )}
      </div>

      <Input
        label={"Profile URL"}
        type="text"
        id="profile_url"
        name="profile_url"
        className={`pro-input lg ${getFieldError("profile_url") && " error"}`}
        {...formik.getFieldProps("profile_url")}
        error={getFieldError("profile_url")}
        errorMessage={getFieldError("profile_url")}
      />

      <Input
        label={"Followers"}
        type="text"
        id="followers"
        name="followers"
        className={`pro-input lg ${getFieldError("followers") && " error"}`}
        {...formik.getFieldProps("followers")}
        error={getFieldError("followers")}
        errorMessage={getFieldError("followers")}
      />

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          type="button"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default OverviewTab;
