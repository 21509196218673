import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/User/userSlice";
import {
  users,
  useGetUsersDataQuery,
  useUpdateUserStatusDataMutation,
  useUpdateUsersTableHeadDataMutation,
} from "../../../Store/Queries/Users";
// import { subDays } from "date-fns";
import { getFormatedDate } from "../../../utils/Table";
import SocialIcon from "../../Global/SocialIcon";
import { current } from "@reduxjs/toolkit";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../Assets";
import { exportUserTable, getUserDetails } from "./api";
import { toast } from "react-toastify";
import { getInterestData } from "../../../Store/Slices/Global";
const useUsers = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usersState = useSelector((state) => state.users);
  const { tableFields, showEditModal,currentUrl} = useSelector((state) => state.global);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const [initialDate, setInitialDate] = useState({
    // startDate: subDays(new Date(), 30),
    // endDate: subDays(new Date(), -30),
    startDate: usersState.startDate ? usersState.startDate : null,
    endDate: usersState.endDate ? usersState.endDate : null,
    key: "selection",
  });
  const {
    data: usersList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetUsersDataQuery({
    items_per_page: usersState.currentPageSize,
    current_page: usersState.currentPage,
    sort_by: usersState.sortBy,
    sort_order: usersState.sortOrder,
    search: usersState.search,
    category:usersState.category?.id,
    social_media_status: usersState.social_media_status?.id,
    social_media_type: usersState.social_media_type?.name,
    social_media: usersState?.activeTab,
    start_date: usersState.startDate
      ? getFormatedDate(usersState.startDate)
      : "",
    end_date: usersState.endDate ? getFormatedDate(usersState.endDate) : "",
  });

  const [updateUsersFields] = useUpdateUsersTableHeadDataMutation();
  const [updateStatus] = useUpdateUserStatusDataMutation();

  useEffect(() => {
    //while swithing tabs,the current page will be 1 and page size will be 10
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = usersList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [usersList]);

  useEffect(() => {
    if (currentUrl?.includes("user-details")) {
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
        })
      );
      dispatch(
        globalUpdateConfig((state) => {
          state.currentUrl=""
        })
      );
    }else{
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
          state.currentPage = 1;
          state.currentPageSize = 10;
          state.social_media_status = "";
          state.social_media_type = "";
          state.category ="";
        })
      );
    }
    const clearSelectionTimeout = setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }, 200);

    return () => clearTimeout(clearSelectionTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const navigation = [
    {
      label: "All",
      title: "All",
      link: `/user?activeTab=all`,
      active: activeTab === "all",
    },
    {
      label: "Instagram",
      title: "Instagram",
      link: `/user?activeTab=instagram`,
      active: activeTab === "instagram",
    },
    {
      label: "Facebook",
      title: "Facebook",
      link: `/user?activeTab=facebook`,
      active: activeTab === "facebook",
    },
    {
      label: "Youtube",
      title: "Youtube",
      link: `/user?activeTab=youtube`,
      active: activeTab === "youtube",
    },
    {
      label: "Tiktok",
      title: "Tiktok",
      link: `/user?activeTab=tiktok`,
      active: activeTab === "tiktok",
    },
    // {
    //   label: "Linkedin",
    //   title: "Linkedin",
    //   link: `/user?activeTab=linkedin`,
    //   active: activeTab === "linkedin",
    // },
    {
      label: "Google",
      title: "Google",
      link: `/user?activeTab=google`,
      active: activeTab === "google",
    },
  ];

  const handleStatusChange = (data) => {
    const newStatus = data.status === "Active" ? true : false;
    let params = {
      user_id: data?._id,
      status: newStatus ? 2 : 1,
    };
    updateStatus(params);
    dispatch(
      users.util.updateQueryData(
        "getUsersData",
        {
          items_per_page: usersState.currentPageSize,
          current_page: usersState.currentPage,
          sort_by: usersState.sortBy,
          sort_order: usersState.sortOrder,
          search: usersState.search,
          social_media: usersState?.activeTab,
          start_date: usersState.startDate
            ? getFormatedDate(usersState.startDate)
            : "",
          end_date: usersState.endDate
            ? getFormatedDate(usersState.endDate)
            : "",
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === "Active" ? "Inactive" : "Active",
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              data: newResult,
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      interest_values: (field, data) => {
        const interests = data[field];
        if (interests?.length > 2) {
          const displayedInterests = interests.slice(0, 2); // Take only the first two items
          return (
            <div className=" pro-d-flex pro-flex-wrap pro-gap-2">
              {displayedInterests.map((value, index) => (
                <span key={index} className={`pro-badge badge-primary-light`}>
                  {value}
                </span>
              ))}
              <span className={`pro-badge badge-primary-light`}>...</span>
            </div>
          );
        } else {
          return (
            <div className=" pro-d-flex pro-flex-wrap pro-gap-2">
              {interests?.map((value, index) => (
                <span key={index} className={`pro-badge badge-primary-light`}>
                  {value}
                </span>
              ))}
            </div>
          );
        }
      },
      name: (feild, data) => (
        <div className="pro-pnt text-truncate">
          <IconText
            icon={
              <img
                src={data["thumb_image"] ?? Assets.PROFILE_USER}
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            onClick={() => {
              if (usersList?.data?.permissions?.can_view) {
                navigate({
                  pathname: `/user-details/${data?._id}active="${activeTab}"&type="user"`,
                });
                sessionStorage.setItem("active-user", `${data?._id}`);
              }
            }}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      country: (feild, data) => (
        <p className="pro-mb-0 no-wrap ">{data[feild]}</p>
      ),
      social_media: (feild, data) => <SocialIcon icons={data[feild]} />,
      status: (feild, data) => {
        const status = data[feild] === "Active";
        return (
          <>
            {usersList?.data?.permissions?.can_change_status ? (
              <div className="pro-toggle">
                <div className="pro-toggle-box">
                  <input
                    type="checkbox"
                    role="switch"
                    id="status"
                    checked={status}
                    onChange={() => handleStatusChange(data)}
                  />
                  <span></span>
                </div>
              </div>
            ) : data[feild] === "Active" ? (
              <span className={"badge-success-outline pro-badge"}>
                {data[feild]}
              </span>
            ) : (
              <span className={"badge-warning-outline pro-badge"}>
                {data[feild]}
              </span>
            )}
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (usersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = usersState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.startDate = new Date(date.startDate);
        state.endDate = new Date(date.endDate);
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.startDate = "";
        state.endDate = "";
        state.category = "";
        state.social_media_status = "";
        state.social_media_type = "";
      })
    );
    setInitialDate({
      startDate: "",
      endDate: "",
      key: "selection",
    });
  };

  const handleCreateClick = () => {
    dispatch(getInterestData());
    dispatch(
      updateConfig((state) => {
        state.createModal = true;
        state.isEdit = false;
        state.profileThumb = "";
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };

  const handleEditAction = (e) => {
    dispatch(getInterestData());
    const id = e?.[0];
    dispatch(
      updateConfig((state) => {
        state.createModal = true;
        state.isEdit = true;
        state.selectedIteam = id;
      })
    );
    getUserDetails(id).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.userDetails = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleExportTable = () => {
    let data = {
      search: usersState.search,
      social_media: usersState?.activeTab,
      start_date: usersState.startDate?getFormatedDate(usersState.startDate):"",
      end_date: usersState.startDate?getFormatedDate(usersState.endDate):"",
    };
    exportUserTable(data).then((response) => {
      if (response?.data?.code === 200) {
        toast.success(
          response?.data?.message ?? "Table has send to registered email"
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  return {
    usersList,
    isLoading,
    usersState,
    isFetching,
    tableFields,
    initialDate,
    showEditModal,
    paginationOptions,
    tabNavs: navigation,
    activeTab,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleClearClick,
    handlePagination,
    handleEditAction,
    handleDateChange,
    handleCloseModal,
    handleExportTable,
    handleCreateClick,
    updateUsersFields,
    handleEditColumnsClick,
    handleCloseFilter,
    handleFilterClick,
  };
};

export default useUsers;
