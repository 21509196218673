import React from "react";
import useAddUser from "./useAddUser";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import FileUpload from "../../../Global/FileUpload";

const AddUser = ({ refetch }) => {
  const {
    formik,
    isEdit,
    minDate,
    basicData,
    interestData,
    submitStatus,
    profileThumb,
    countryCodeOptions,
    getFieldError,
    handleDobChange,
    handleCloseModal,
    handleProfileChange,
    handleProfileDelete,
  } = useAddUser({ refetch });
  return (
    <div className="pro-h-100 pro-d-flex pro-flex-column">
      <div className="pro-mb-4">
        <FileUpload
          id={"profile"}
          title={"Profile Picture"}
          filePreview={profileThumb ?? ""}
          handleFileChange={handleProfileChange}
          handleFileRemove={handleProfileDelete}
          isError={
            formik?.touched?.profile && formik?.errors?.profile ? true : false
          }
          errorMsg={formik?.errors?.profile}
          maxSize="5"
          status={"idle"}
        />
      </div>
      <Input
        label={"Full Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <Input
        label={"Email"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="gender" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Gender
        </label>
        <Select
          id="gender"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("gender") && " error"}`}
          classNamePrefix="pro-input"
          name="gender"
          options={basicData?.gender?.filter((val) => val?.id !== "All")}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.gender ?? ""}
          onBlur={formik.handleBlur("gender")}
          onChange={(value) => formik.setFieldValue("gender", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("gender") && (
          <span className="error-text">{getFieldError("gender")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Contact Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="country_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.country_code}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("country_code")}
                menuPlacement="auto"
                onChange={(value) =>
                  formik.setFieldValue("country_code", value)
                }
                isDisabled
              ></Select>
            </div>
            <input
              type="text"
              id="mobile"
              name="mobile"
              className={`pro-input lg ${
                formik.errors.mobile && formik.touched.mobile && " error"
              }`}
              {...formik.getFieldProps("mobile")}
            />
          </div>
          {formik.errors.mobile && formik.touched.mobile && (
            <span className="error-text">{formik.errors.mobile}</span>
          )}
        </div>
      </div>
      <div className={`col-12 pro-mb-4`}>
        <label htmlFor="dob" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of birth
        </label>
        <input
          max={minDate.toISOString().slice(0, 10)}
          className={`pro-input lg  ${getFieldError("dob") && " error"}`}
          type="date"
          name="dob"
          placeholder="Select Start Date"
          onChange={handleDobChange}
          onBlur={formik?.handleBlur("dob")}
          value={formik.values.dob ?? ""}
        />
        {formik.touched.dob && formik.errors.dob && (
          <span className="error-text">{formik.errors.dob}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="country" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Country
        </label>
        <Select
          id="country"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("country") && " error"}`}
          classNamePrefix="pro-input"
          name="country"
          options={basicData?.country}
          getOptionValue={(option) => option?.unique_country_id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.country ?? ""}
          onBlur={formik.handleBlur("country")}
          onChange={(value) => {
            formik.setFieldValue("country", value ?? "");
            formik?.setFieldValue("emirates", "");
          }}
          menuPlacement="auto"
          isClearable
          isDisabled
        />
        {getFieldError("country") && (
          <span className="error-text">{getFieldError("country")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="emirates"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Emirates
        </label>
        <Select
          id="emirates"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("emirates") && " error"}`}
          classNamePrefix="pro-input"
          name="emirates"
          options={formik?.values?.country?.state}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.emirates ?? ""}
          onBlur={formik.handleBlur("emirates")}
          onChange={(value) => formik.setFieldValue("emirates", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("emirates") && (
          <span className="error-text">{getFieldError("emirates")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="city" className="pro-font-sm pro-mb-1 pro-fw-medium">
          City
        </label>
        <Select
          id="city"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("city") && " error"}`}
          classNamePrefix="pro-input"
          name="city"
          options={formik?.values?.emirates?.city}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.city ?? ""}
          onBlur={formik.handleBlur("city")}
          onChange={(value) => formik.setFieldValue("city", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("city") && (
          <span className="error-text">{getFieldError("city")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="languages"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Languages
        </label>
        <Select
          id="languages"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("languages") && " error"}`}
          classNamePrefix="pro-input"
          isMulti
          name="languages"
          options={basicData?.language ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.languages?.map((v) => v)}
          onBlur={formik.handleBlur("languages")}
          onChange={(value) =>
            formik.setFieldValue(
              "languages",
              value.map((v) => v)
            )
          }
          menuPlacement="auto"
          isClearable
        />
        {formik.errors.languages && formik.touched.languages && (
          <span className="error-text">{formik.errors.languages}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="interests"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Interests
        </label>
        <Select
          id="interests"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("interests") && " error"}`}
          classNamePrefix="pro-input"
          isMulti
          name="interests"
          options={interestData?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.interests?.map((v) => v ?? "")}
          onBlur={formik.handleBlur("interests")}
          onChange={(value) =>
            formik.setFieldValue(
              "interests",
              value.map((v) => v)
            )
          }
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("interests") && (
          <span className="error-text">{getFieldError("interests")}</span>
        )}
      </div>
      <div className={`col-12 pro-d-flex pro-justify-end pro-pb-5`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3 pro-px-5"}
          onClick={formik.handleSubmit}
          disabled={submitStatus === "pending"}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddUser;
