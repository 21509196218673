import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changeInterestStatus } from "../api";
import { updateConfig } from "../../../../Store/Slices/InterestChange/interestChangeSlice";

const useReject = ({ refetch }) => {
  const dispatch = useDispatch();
  const { rejectId } = useSelector((state) => state.interestChange);
  const formik = useFormik({
    initialValues: { reason: "", ar_reason: "" },
    validate: (values) => {
      let errors = {};
      if (!values.reason) {
        errors.reason = "Reason Required";
      }
      if (!values.ar_reason) {
        errors.ar_reason = "Reason (سبب) Required";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      let body = {
        request_id: rejectId,
        status: 3,
        reject_reason: values?.reason,
        ar_reject_reason: values?.ar_reason,
      };
      changeInterestStatus(body).then((response) => {
        if (response?.data?.code === 200) {
          setSubmitting(false);
          handleCloseModal();
          refetch();
          toast.success("Rejected");
        } else {
          setSubmitting(false);
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.rejectModal = false;
      })
    );
  };
  return {
    formik,
    getFieldError,
    handleCloseModal,
  };
};

export default useReject;
