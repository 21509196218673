import { useSelector } from "react-redux";
import { contentVerify, deleteUser, getVerifyData } from "../api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Campaign/ContentVerification/contentVerificationSlice";
import { useEffect } from "react";

const useVerifyContent = ({ refetch }) => {
  const { verifyData, selectedId, rejectBox1, rejectBox2 } = useSelector(
    (state) => state.contentVerification
  );

  const dispatch = useDispatch();

  useEffect(() => {
    clearBoxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearBoxes = () => {
    dispatch(
      updateConfig((state) => {
        state.rejectBox1 = false;
        state.rejectBox2 = false;
      })
    );
  };

  const formik1 = useFormik({
    initialValues: {
      reason: "",
      ar_reason: "",
      type: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values?.reason) {
        errors.reason = "*Reason Required";
      }
      if (!values?.ar_reason) {
        errors.ar_reason = "*Reason (سبب) Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        user_campaign_id: selectedId,
        content_type: 1,
        status: 3,
        reason: values?.reason,
        ar_reason: values?.ar_reason,
      };
      if (values.type === "block") {
        delete data.content_type;
        delete data.status;
        deleteUser(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Blocked");
            clearBoxes();
            refetch();
            dispatch(updateConfig((state) => (state.verifyModal = false)));
          } else {
            toast.error("Something went wrong");
          }
        });
      } 
      else {
        contentVerify(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Rejected");
            clearBoxes();
            refetch();
            const params = {
              user_campaign_id: selectedId,
            };
            getVerifyData(params).then((response) => {
              if (response?.data?.code === 200) {
                dispatch(
                  updateConfig(
                    (state) => (state.verifyData = response?.data?.data)
                  )
                );
              } else {
                toast.error("Something went wrong");
              }
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const formik2 = useFormik({
    initialValues: {
      reason: "",
      ar_reason: "",
      type: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values?.reason) {
        errors.reason = "* Reason Required";
      }
      if (!values?.ar_reason) {
        errors.ar_reason = "*Reason (سبب) Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        user_campaign_id: selectedId,
        content_type: 2,
        status: 3,
        reason: values?.reason,
        ar_reason: values?.ar_reason,
      };
      if (values.type === "block") {
        delete data.content_type;
        delete data.status;
        deleteUser(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Blocked");
            clearBoxes();
            refetch();
            dispatch(updateConfig((state) => (state.verifyModal = false)));
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        contentVerify(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Rejected");
            clearBoxes();
            refetch();
            const params = {
              user_campaign_id: selectedId,
            };
            getVerifyData(params).then((response) => {
              if (response?.data?.code === 200) {
                dispatch(
                  updateConfig(
                    (state) => (state.verifyData = response?.data?.data)
                  )
                );
              } else {
                toast.error("Something went wrong");
              }
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  const getFieldError1 = (fieldName) => {
    if (formik1.touched[fieldName] && formik1.errors[fieldName]) {
      return formik1.errors[fieldName];
    }
    return "";
  };
  const getFieldError2 = (fieldName) => {
    if (formik2.touched[fieldName] && formik2.errors[fieldName]) {
      return formik2.errors[fieldName];
    }
    return "";
  };

  const handleRejectClick = (type, btnType) => {
    if (type === 1) {
      if (formik1.values.type === "" || formik1.values.type !== btnType) {
        dispatch(updateConfig((state) => (state.rejectBox1 = true)));
        formik1?.setFieldValue("type", btnType);
      } else {
        dispatch(updateConfig((state) => (state.rejectBox1 = false)));
        formik1?.setFieldValue("type", "");
      }
    } else {
      if (formik2.values.type === "" || formik2.values.type !== btnType) {
        dispatch(updateConfig((state) => (state.rejectBox2 = true)));
        formik2?.setFieldValue("type", btnType);
      } else {
        dispatch(updateConfig((state) => (state.rejectBox2 = false)));
        formik2?.setFieldValue("type", "");
      }
    }
  };

  const handleApproveClick = (type) => {
    let data = {
      user_campaign_id: selectedId,
      content_type: type,
      status: 2,
    };
    contentVerify(data).then((response) => {
      if (response?.data?.code === 200) {
        toast.success("Approved");
        refetch();
        const params = {
          user_campaign_id: selectedId,
        };
        getVerifyData(params).then((response) => {
          if (response?.data?.code === 200) {
            dispatch(
              updateConfig((state) => (state.verifyData = response?.data?.data))
            );
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  return {
    formik1,
    formik2,
    rejectBox1,
    rejectBox2,
    verifyData,
    getFieldError1,
    getFieldError2,
    handleRejectClick,
    handleApproveClick,
  };
};

export default useVerifyContent;
