import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import useWalletTransaction from "./useWalletTransaction";
import { FaSort } from "react-icons/fa";
//   import Reject from "./Reject";
import { Pagination } from "../../Global/Pagination";
import ViewLinks from "./ViewLinks";
import FilterWallet from "./FilterWallet";
import Style from "./wallet.module.scss"

const WalletTransaction = () => {
  const {
    getRow,
    refetch,
    handleSort,
    updateFields,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick,
    handleLinkClose,
    handleEditColumnsClick,
    handleDateChange,
    handleFilterClick,
    handleCloseFilter,
    initialDate,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    walletTransactionList,
    walletTransactionState,
  } = useWalletTransaction();
  return (
    <div>
      <HeadingGroup title={"Wallet Transactions"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6 ${Style.search_order}`}>
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={true}
            handleActionClick={handleEditColumnsClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            showClearFilters
            handleClear={handleClearClick}
            searchInputProps={{ value: walletTransactionState?.search }}
            isDateRange
            showDateRange={true}
            initialDateRange={initialDate}
            onDateChange={handleDateChange}
            extraFilters={
              <div className="col-auto filter">
                <button
                  className={"pro-btn pro-items-center pro-btn-outline pro-h-100"}
                  onClick={handleFilterClick}
                >
                  Filter
                </button>
                </div>
            }
          />


        {walletTransactionList?.data?.data?.length > 0 ? (
          <>
            <Table
              multiSelect={false}
              data={walletTransactionList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              clear={walletTransactionState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={walletTransactionState?.currentPageSize}
              editable={false}
              deletable={false}
              assignable={false}
              showCheckBox={
                walletTransactionList?.data?.permissions?.can_update
              }
            />
            {walletTransactionList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={walletTransactionState?.currentPage}
                totalPageCount={Math.ceil(
                  walletTransactionList?.data?.total_count /
                    walletTransactionState.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={walletTransactionList?.data?.fields}
              moduleId={walletTransactionList?.data?.table_id}
              updateData={updateFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={walletTransactionState?.viewLinkModal}
          title="View Links"
          handleClose={handleLinkClose}
          closeicon={<span className="material-symbols-outlined">close</span>}
        >
          <ViewLinks data={walletTransactionState?.linkData}/>
        </OffCanvasLayout>
        <OffCanvasLayout
          show={walletTransactionState?.createFilterModal}
          handleClose={handleCloseFilter}
          title={"Filter"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <FilterWallet refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default WalletTransaction;
