import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/FAQ/FAQSlice";
import { createFAQ, updateFAQ } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";

const useAddFAQ = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, itemId } = useSelector((state) => state.FAQ);
  const formik = useFormik({
    initialValues: {
      question: "",
      ar_question: "",
      answer: "",
      ar_answer: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.question) {
        errors.question = "*Question Is Required";
      }
      if (!values.ar_question) {
        errors.ar_question = "*Question (تساؤل) Is Required";
      }
      if (!values.answer) {
        errors.answer = "*Answer Is Required";
      }
      if (!values.ar_answer) {
        errors.ar_answer = "*Answer (إجابة) Is Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (isEdit) {
        let data = {
          faq_id: itemId,
          question: values?.question,
          ar_question: values?.ar_question,
          answer: values?.answer,
          ar_answer: values?.ar_answer,
          status: 1,
        };
        updateFAQ(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Added");
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let data = {
          question: values?.question,
          ar_question: values?.ar_question,
          answer: values?.answer,
          ar_answer: values?.ar_answer,
          status: 1,
        };
        createFAQ(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Added");
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit && itemData) {
      formik?.setFieldValue("question", itemData?.question);
      formik?.setFieldValue("answer", itemData?.answer);
      formik?.setFieldValue("ar_question", itemData?.ar_question);
      formik?.setFieldValue("ar_answer", itemData?.ar_answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, itemData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
      })
    );
  };
  return {
    formik,
    isEdit,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddFAQ;
