import { useNavigate, useParams } from "react-router";
import { useGetBrandDetailsDataQuery } from "../../../Store/Queries/Brand";

const useBrandsDetailsLayout = () => {
  const { brandID } = useParams();
  const navigate = useNavigate();
  const activeUser = brandID ?? sessionStorage.getItem("active-user");
  const { data: brandData, isFetching } = useGetBrandDetailsDataQuery({
    user_id: activeUser,
  });

  const label = {
    contact_number: "Contact Number",
    email: "Email",
    website: "Website",
    description: "Description",
  };

  const phone = brandData?.data?.country_code.concat(
    " " + brandData?.data?.contact_number
  );

  const nameWithAge = brandData?.data?.name;
  const value = {
    contact_number: `${phone ?? "Not specified"}`,
    email: `${brandData?.data?.email ?? "Not specified"}`,
    website: `${brandData?.data?.website_url ?? "Not specified"}`,
    description: `${brandData?.data?.description ?? "Not specified"}`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const handleBackClick = () => {
    navigate("/brand");
  };

  return {
    isFetching,
    brandData,
    basicDetails,
    nameWithAge,
    handleBackClick,
  };
};

export default useBrandsDetailsLayout;
