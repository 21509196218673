import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../API";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  chatModal: false,
  clearState: false,
  chatId: "",
  chats: {},
  chatStatus: "idle",
  chatTodal: null,
  openedChatStatus: "",
};

export const getChatData = createAsyncThunk(
  "admin/support/list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/support/view?items_per_page=15&current_page=${params?.page}&support_id=${params?.support_id}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const SupportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatData.pending, (state) => {
      state.chatStatus = "pending";
    });
    builder.addCase(getChatData.fulfilled, (state, action) => {
      if (
        state.chats?.[action.meta?.arg?.support_id] &&
        !action.meta?.arg?.clearState
      ) {
        if (action.payload?.data?.data?.data?.messages) {
          state.chats[action.meta?.arg?.support_id] = {
            ...action.payload?.data?.data?.data,
            messages: [
              ...state.chats[action.meta?.arg?.support_id].messages,
              ...action.payload?.data?.data?.data?.messages,
            ],
          };
          state.chatTodal = action.payload?.data?.data?.total_count;
        } else {
          state.chats[action.meta?.arg?.support_id] = {
            ...action.payload?.data?.data?.data,
            messages: [...state.chats[action.meta?.arg?.support_id].messages],
          };
          state.chatTodal = action.payload?.data?.data?.total_count;
        }
      } else {
        state.chats[action.meta?.arg?.support_id] = {
          ...action.payload?.data?.data?.data,
          page: 1,
        };
        state.chatTodal = action.payload?.data?.data?.total_count;
      }
      state.chatStatus = "fulfilled";
    });
    builder.addCase(getChatData.rejected, (state) => {
      state.chatStatus = "failed";
    });
  },
});

export const { updateConfig } = SupportSlice.actions;
export default SupportSlice.reducer;
