import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useUpdatePayoutStatusDataMutation } from "../../../../Store/Queries/Payments";
import { updateConfig } from "../../../../Store/Slices/Payments/paymentsSlice";
import { toast } from "react-toastify";

const useDecline = ({ paymentReportRefetch }) => {
  const dispatch = useDispatch();
  const paymentsState = useSelector((state) => state?.payments);
  const [updateStatus] = useUpdatePayoutStatusDataMutation();
  const formik = useFormik({
    initialValues: {
      reason: "",
      ar_reason: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values?.reason?.trim()) {
        errors.reason = "Reason is required";
      } else if (values?.reason?.trim()?.length <= 3) {
        errors.reason = "Reason must be greater than 3 characters";
      }
      if (!values?.ar_reason?.trim()) {
        errors.ar_reason = "Reason (سبب) is required";
      } else if (values?.ar_reason?.trim()?.length <= 3) {
        errors.ar_reason = "Reason (سبب) must be greater than 3 characters";
      }
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        wallet_transaction_id: paymentsState?.wallet_transaction_id,
        status: paymentsState?.status,
        reject_reason: values?.reason,
        ar_reject_reason: values?.ar_reason,
      };
      updateStatus(data)?.then((response) => {
        if (response?.data?.code === 200) {
          toast?.success("Stopped!");
          handleCloseModal();
          paymentReportRefetch();
        } else {
          toast?.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik?.touched[fieldName] && formik?.errors[fieldName]) {
      return formik?.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.declineStopModal = false;
      })
    );
  };
  return {
    formik,
    getFieldError,
    handleCloseModal,
  };
};

export default useDecline;
