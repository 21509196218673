import { useSelector } from "react-redux";
import { useGetLinksDataQuery } from "../../../Store/Queries/Users";
import { toast } from "react-toastify";

const useLink = () => {
  const { campId } = useSelector((state) => state.users);
  const { data: linksData, isFetching } = useGetLinksDataQuery({
    user_campaign_id: campId,
  });

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyURL = (copyText) => {
    copyTextToClipboard(copyText).then(() => {
      toast.success("Copied To Clipboard");
    });
  };
  return {
    linksData,
    isFetching,
    handleCopyURL,
  };
};

export default useLink;
