import React from "react";
// import Style from "../form.module.scss";
import useEarnings from "./useEarnings";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import InfluencerEarnings from "./InfluencerEarnings";
import Select from "react-select";

const Earnings = ({
  formik,
  getFieldError,
  selectedOption,
  setSelectedOption,
  isCampaignStatus
}) => {
  const { basicData, handleCloseModal, handleNext, handleRadioChange } =
    useEarnings({
      formik,
      setSelectedOption,
    });
  return (
    <div className="pro-d-flex pro-flex-column pro-h-100">
      <div className={`pro-mb-5`}>
        <div className="row">
          <div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="radio_type"
                id="flat"
                checked={selectedOption === "flat"}
                onChange={handleRadioChange}
                disabled={isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
              />
              <label className="pro-check-label" htmlFor="flat">
                Flat
              </label>
            </div>
          </div>
          <div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="radio_type"
                id="engagment_rate"
                checked={selectedOption === "engagment_rate"}
                onChange={handleRadioChange}
                disabled={
                  formik?.values?.platform?.[0]?.p_name?.name === "google"||
                  isCampaignStatus === "stopped"||isCampaignStatus === "completed"
                }
              />
              <label className="pro-check-label" htmlFor="engagment_rate">
                Engagement Rate
              </label>
            </div>
          </div>
        </div>
      </div>

      <Input
        label={"Total Budget (AED)"}
        type="text"
        id="budget"
        name="budget"
        className={`pro-input lg ${getFieldError("budget") && " error"}`}
        {...formik.getFieldProps("budget")}
        error={getFieldError("budget")}
        errorMessage={getFieldError("budget")}
        disabled={isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
      />
      <div className="pro-mb-4">
        <label className="pro-font-sm pro-mb-1 pro-fw-medium">
          Influencer Earnings
        </label>
        {formik?.values?.platform?.map((value, index) => {
          return (
            <React.Fragment key={index}>
              <InfluencerEarnings
                value={value}
                index={index}
                key={index}
                formik={formik}
                selectedOption={selectedOption}
                isCampaignStatus={isCampaignStatus}
              />
              {formik.touched.influencer_earnings?.[index]?.amount &&
                formik.errors.influencer_earnings?.[index]?.amount && (
                  <span className={`error-custom pro-pb-1 pro-px-3 pro-mt-0`}>
                    {formik.errors.influencer_earnings?.[index]?.amount}
                  </span>
                )}
            </React.Fragment>
          );
        })}
      </div>
      {selectedOption === "engagment_rate" && (
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="engagment_type"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Engagment Type
          </label>
          <Select
            id="engagment_type"
            name="engagment_type"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("engagment_type") && " error"
            }`}
            classNamePrefix="pro-input"
            options={basicData?.engagement_type ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.engagment_type}
            onBlur={formik.handleBlur("engagment_type")}
            onChange={(value) =>
              formik?.setFieldValue("engagment_type", value || null)
            }
            menuPlacement="auto"
            isClearable
            isDisabled={isCampaignStatus === "live" || isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
          />
          {getFieldError("engagment_type") && (
            <span className="error-text">
              {getFieldError("engagment_type")}
            </span>
          )}
        </div>
      )}
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Payment Description
          </label>
          <textarea
            id="earnings_description"
            rows={3}
            name="earnings_description"
            className={`pro-input lg ${
              formik.errors.earnings_description &&
              formik.touched.earnings_description &&
              "error"
            }`}
            value={formik?.values?.earnings_description ?? ""}
            onBlur={formik?.handleBlur("earnings_description")}
            onChange={(e) =>
              formik.setFieldValue("earnings_description", e.target.value)
            }
            disabled={isCampaignStatus === "live" || isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
          ></textarea>
          {formik.touched.earnings_description &&
            formik.errors.earnings_description && (
              <span className="error-text">
                {formik.errors.earnings_description}
              </span>
            )}
        </div>
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            (وصف الدفع ) Payment Description
          </label>
          <textarea
            id="ar_earnings_description"
            rows={3}
            name="ar_earnings_description"
            className={`pro-input lg ${
              formik.errors.ar_earnings_description &&
              formik.touched.ar_earnings_description &&
              "error"
            }`}
            value={formik?.values?.ar_earnings_description ?? ""}
            onBlur={formik?.handleBlur("ar_earnings_description")}
            onChange={(e) =>
              formik.setFieldValue("ar_earnings_description", e.target.value)
            }
            disabled={isCampaignStatus === "live" || isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
          ></textarea>
          {formik.touched.ar_earnings_description &&
            formik.errors.ar_earnings_description && (
              <span className="error-text">
                {formik.errors.ar_earnings_description}
              </span>
            )}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          type="button"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default Earnings;
