import Assets from "../../../../Assets";

const useViewLinks = () => {
  const toPascalCase = (str) => {
    return str
      .replace(/(\w)(\w*)/g, function (_, firstChar, rest) {
        return firstChar.toUpperCase() + rest.toLowerCase();
      })
      .replace(/\s+/g, "");
  };
  const getSocialMediaName = (type) => {
      switch (type) {
        case "video":
          return Assets.VIDEO;
        case "reels":
          return Assets.REELS;
        case "post":
          return Assets.POSTS;
        case "shorts":
          return Assets.SHORTS;
        default:
          return Assets.OTHER;
      }

  };

  return { toPascalCase, getSocialMediaName };
};

export default useViewLinks;
