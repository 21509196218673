import { getAxiosInstance } from "../../../API";

export const createAdmin = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/sub-admin/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateAdmin = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/sub-admin/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const viewAdmin = async (data) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/admin/sub-admin/view?subadmin_id=${data?.id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
