import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../API";

const initialState = {
  tableFields: {},
  otpGenerated: "",
  isLogged: false,
  showEditModal: false,
  menu_permissions_status: "idle",
  menu_permissions: {},
  basic_data_status: "idle",
  basicData: {},
  message_data_status: "idle",
  messageData: {},
  drawerTheme: "expanded",
  currentTheme: "light",
  profileModal: false,
  profile_data_status: "",
  profileData: "",
  profileThumb: "",
  interest_data_status: "idle",
  interestData: [],
};

export const getMenuPermissions = createAsyncThunk(
  "global/getMenuPermissions",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/menu`, body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getBasicData = createAsyncThunk(
  "global/getBasicData",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/admin/basic-details", body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfileData = createAsyncThunk(
  "global/getProfileData",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/admin/profile/view", body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getInterestData = createAsyncThunk(
  "global/getInterestData",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/admin/interest", body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomMessages = createAsyncThunk(
  "global/getCustomMessages",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/admin/notification_template/basic-list", body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    // menu permissions
    builder.addCase(getMenuPermissions.pending, (state) => {
      state.menu_permissions_status = "pending";
    });
    builder.addCase(getMenuPermissions.fulfilled, (state, action) => {
      localStorage.setItem(
        "menu_permissions",
        JSON.stringify(action.payload.data.data)
      );
      state.menu_permissions = action.payload.data.data;
      state.menu_permissions_status = "fulfilled";
    });
    builder.addCase(getMenuPermissions.rejected, (state) => {
      state.menu_permissions_status = "rejected";
    });

    // basic data
    builder.addCase(getBasicData.pending, (state) => {
      state.basic_data_status = "pending";
    });
    builder.addCase(getBasicData.fulfilled, (state, action) => {
      localStorage.setItem(
        "basicData",
        JSON.stringify(action.payload.data.data)
      );
      state.basicData = action.payload.data.data;
      state.basic_data_status = "fulfilled";
    });
    builder.addCase(getBasicData.rejected, (state) => {
      state.message_data_status = "rejected";
    });

    // Custom Message data
    builder.addCase(getCustomMessages.pending, (state) => {
      state.message_data_status = "pending";
    });
    builder.addCase(getCustomMessages.fulfilled, (state, action) => {
      state.messageData = action.payload.data.data;
      state.message_data_status = "fulfilled";
    });
    builder.addCase(getCustomMessages.rejected, (state) => {
      state.message_data_status = "rejected";
    });
    // profile data
    builder.addCase(getProfileData.pending, (state) => {
      state.profile_data_status = "pending";
    });
    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.profileData = action.payload.data.data;
      state.profile_data_status = "fulfilled";
    });
    builder.addCase(getProfileData.rejected, (state) => {
      state.profile_data_status = "rejected";
    });

    builder.addCase(getInterestData.pending, (state) => {
      state.interest_data_status = "pending";
    });
    builder.addCase(getInterestData.fulfilled, (state, action) => {
      state.interestData = action.payload.data.data;
      state.interest_data_status = "fulfilled";
    });
    builder.addCase(getInterestData.rejected, (state) => {
      state.interest_data_status = "rejected";
    });
  },
});

export const { updateConfig } = globalSlice.actions;
export default globalSlice.reducer;
