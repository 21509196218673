import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  activeTab: "pending",
  rejectModal: false,
  rejectId: "",
  isPending: false,
};

const interestChangeSlice = createSlice({
  name: "interestChange",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = interestChangeSlice.actions;
export default interestChangeSlice.reducer;
