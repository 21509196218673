import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  addModal: false,
  logoModal: false,
  logo: "",
  logoPreview: "",
  selectedItem: "",
  isEdit: false,
  itemData: "",
  detailsPage: {
    currentPage: 1,
    currentFilter: "all",
    currentPageSize: 10,
    sortBy: "",
    sortOrder: "",
    search: "",
    startDate: subDays(new Date(), 60),
    endDate: subDays(new Date(), -60),
  },
};

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = brandSlice.actions;
export default brandSlice.reducer;
