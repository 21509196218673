import { getAxiosInstance } from "../../API";

export const payoutTableExport = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/wallet/export", body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
