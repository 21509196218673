import React from "react";
import Style from "../dos.module.scss";
import useDos from "./useDo";

const Do = ({ formik, itmIndex,isCampaignStatus}) => {
  const { handleRemoveField } = useDos({ formik });
  return (
    <div className={Style.outer_wrap}>
      <div
        className={`${Style.box_container} pro-py-2 pro-d-flex pro-flex-column pro-px-2 pro-rounded-3`}
      >
        <label className="pro-font-sm pro-mb-1 pro-fw-medium">Dos</label>
        {formik?.values?.dos?.length > 1 && (
          <div className={`pro-d-flex ${Style.dlt_btn}`}>
            <button
              className="pro-btn pro-btn-link pro-py-0 pro-px-0"
              onClick={() => handleRemoveField(itmIndex)}
              disabled={isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
            >
              <span className="material-symbols-outlined x4">close</span>
            </button>
          </div>
        )}
        <div className="input-wrap flex-fill">
          <textarea
            id={`do${[itmIndex]}`}
            rows={1}
            name={`do${[itmIndex]}`}
            className={`pro-input lg ${
              formik.errors.dos?.[itmIndex]?.do &&
              formik.touched?.dos?.[itmIndex]?.do &&
              "error"
            }`}
            value={formik?.values?.dos?.[itmIndex]?.do ?? ""}
            onBlur={formik?.handleBlur(`dos.${itmIndex}.do`)}
            onChange={(e) =>
              formik.setFieldValue(`dos.${itmIndex}.do`, e.target.value)
            }
            disabled={isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
          ></textarea>
          {formik.touched.dos?.[itmIndex]?.do &&
            formik.errors.dos?.[itmIndex]?.do && (
              <span className="error-text">
                {formik.errors.dos?.[itmIndex]?.do}
              </span>
            )}
        </div>
        {/* <div
          className={`${Style.delete_btn} pro-justify-center pro-d-flex pro-items-center`}
        >
          {formik?.values?.dos?.length > 1 && (
            <span
              className="material-symbols-outlined x4"
              onClick={() => handleRemoveField(itmIndex)}
            >
              delete
            </span>
          )}
        </div> */}
      </div>

      <div
        className={`${Style.box_container} pro-py-2 pro-d-flex pro-flex-column pro-px-2 pro-rounded-3`}
      >
        <label className="pro-font-sm pro-mb-1 pro-fw-medium pro-rtl">
          ( التابع ) Dos
        </label>
        <div className="input-wrap flex-fill pro-rtl">
          <textarea
            id={`ar_do${[itmIndex]}`}
            rows={1}
            name={`ar_do${[itmIndex]}`}
            className={`pro-input lg ${
              formik.errors.dos?.[itmIndex]?.ar_do &&
              formik.touched?.dos?.[itmIndex]?.ar_do &&
              "error"
            }`}
            value={formik?.values?.dos?.[itmIndex]?.ar_do ?? ""}
            onBlur={formik?.handleBlur(`dos.${itmIndex}.ar_do`)}
            onChange={(e) =>
              formik.setFieldValue(`dos.${itmIndex}.ar_do`, e.target.value)
            }
            disabled={isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
          ></textarea>
          {formik.touched.dos?.[itmIndex]?.ar_do &&
            formik.errors.dos?.[itmIndex]?.ar_do && (
              <span className="error-text">
                {formik.errors.dos?.[itmIndex]?.ar_do}
              </span>
            )}
        </div>
        {/* <div
          className={`${Style.delete_btn} pro-justify-center pro-d-flex pro-items-center`}
        >
          {formik?.values?.dos?.length > 1 && (
            <span
              className="material-symbols-outlined x4"
              onClick={() => handleRemoveField(itmIndex)}
            >
              delete
            </span>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Do;
