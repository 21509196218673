import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useFliterUser from "./useFliterUser";

const FilterUser = ({ refetch, activeTab }) => {
  const { formik, handleCloseModal, basicData, getFieldError } = useFliterUser({
    refetch,
    activeTab,
  });
  return (
    <div className="col">
      {activeTab === "all" ? (
        <>
          <div className="pro-mb-4 input-wrap">
            <label
              htmlFor="social_media_type"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Social media
            </label>
            <Select
              id="social_media_type"
              placeholder={"Social media"}
              className={`pro-input lg  ${
                getFieldError("social_media_type") && " error"
              }`}
              classNamePrefix="pro-input"
              name="social_media_type"
              options={
                basicData?.social_media.filter(
                  (item) => item.name !== "google"
                ) ?? []
              }
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={formik?.values?.social_media_type}
              onBlur={formik.handleBlur("social_media_type")}
              onChange={(value) =>
                formik.setFieldValue("social_media_type", value ?? "")
              }
              menuPlacement="auto"
              isClearable
            />
            {getFieldError("social_media_type") && (
              <span className="error-text">
                {getFieldError("social_media_type")}
              </span>
            )}
          </div>
          <div className="pro-mb-4 input-wrap">
            <label
              htmlFor="social_media_status"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Social media status
            </label>
            <Select
              id="social_media_status"
              placeholder={"Social media status"}
              className={`pro-input lg  ${
                getFieldError("social_media_status") && " error"
              }`}
              classNamePrefix="pro-input"
              name="social_media_status"
              options={basicData?.social_media_status ?? []}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={formik?.values?.social_media_status}
              onBlur={formik.handleBlur("social_media_status")}
              onChange={(value) =>
                formik.setFieldValue("social_media_status", value ?? "")
              }
              menuPlacement="auto"
              isClearable
            />
            {getFieldError("social_media_status") && (
              <span className="error-text">
                {getFieldError("social_media_status")}
              </span>
            )}
          </div>
        </>
      ) : (
        <>
          {/* <div className="pro-mb-4">
            <label
              htmlFor="pending_requests"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Pending requests
            </label>
            <Select
              id="Pending requests"
              placeholder={"pending_requests"}
              className={`pro-input lg`}
              classNamePrefix="pro-input"
              name="pending_requests"
              options={basicData?.pending_requests ?? []}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={formik?.values?.pending_requests}
              onBlur={formik.handleBlur("pending_requests")}
              onChange={(value) =>
                formik.setFieldValue("pending_requests", value ?? "")
              }
              menuPlacement="auto"
              isClearable
            />
          </div> */}
          <div className="pro-mb-4">
            <label
              htmlFor="category"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Category
            </label>
            <Select
              id="category"
              placeholder={"Category"}
              className={`pro-input lg`}
              classNamePrefix="pro-input"
              name="category"
              options={basicData?.influencer_category ?? []}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={formik?.values?.category}
              onBlur={formik.handleBlur("category")}
              onChange={(value) =>
                formik.setFieldValue("category", value ?? "")
              }
              menuPlacement="auto"
              isClearable
            />
          </div>
        </>
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto offcanvas-footer-sticky-btn`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="submit"
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterUser;
