import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Terms/termsSlice";
import { useSelector } from "react-redux";
import { useGetTermsDataQuery } from "../../../Store/Queries/Terms";
import { useEffect } from "react";

const useTerms = () => {
  const { editModal } = useSelector((state) => state.terms);
  const { data: terms = {}, refetch } = useGetTermsDataQuery({
    type: "t&c",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.description = terms?.data?.data?.description;
        state.ar_description = terms?.data?.data?.ar_description;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terms?.data?.data?.description, terms?.data?.data?.ar_description]);

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.editModal = false)));
  };

  const handleOpenModal = () => {
    dispatch(updateConfig((state) => (state.editModal = true)));
  };

  return {
    terms,
    editModal,
    refetch,
    handleCloseModal,
    handleOpenModal,
  };
};

export default useTerms;
