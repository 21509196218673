import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddFAQ from "./useAddFAQ";

const AddFAQ = ({ refetch }) => {
  const { formik, isEdit, getFieldError, handleCloseModal } = useAddFAQ({
    refetch,
  });
  return (
    <div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="question"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Question
          </label>
          <textarea
            id="question"
            rows={2}
            name="question"
            className={`pro-input lg ${getFieldError("question") && "error"} `}
            value={formik?.values?.question ?? ""}
            onChange={(e) => formik.setFieldValue("question", e.target.value)}
          ></textarea>
          {getFieldError("question") && (
            <span className="error-text">{getFieldError("question")}</span>
          )}
        </div>
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label
            htmlFor="ar_question"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            (تساؤل) Question
          </label>
          <textarea
            id="ar_question"
            rows={2}
            name="ar_question"
            className={`pro-input lg ${
              getFieldError("ar_question") && "error"
            } `}
            value={formik?.values?.ar_question ?? ""}
            onChange={(e) =>
              formik.setFieldValue("ar_question", e.target.value)
            }
          ></textarea>
          {getFieldError("ar_question") && (
            <span className="error-text">{getFieldError("ar_question")}</span>
          )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="answer"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Answer
          </label>
          <textarea
            id="answer"
            rows={4}
            name="answer"
            className={`pro-input lg ${getFieldError("answer") && "error"}`}
            value={formik?.values?.answer ?? ""}
            onChange={(e) => formik.setFieldValue("answer", e.target.value)}
          ></textarea>
          {getFieldError("answer") && (
            <span className="error-text">{getFieldError("answer")}</span>
          )}
        </div>
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label
            htmlFor="ar_answer"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            (إجابة) Answer
          </label>
          <textarea
            id="ar_answer"
            rows={4}
            name="ar_answer"
            className={`pro-input lg ${getFieldError("ar_answer") && "error"}`}
            value={formik?.values?.ar_answer ?? ""}
            onChange={(e) => formik.setFieldValue("ar_answer", e.target.value)}
          ></textarea>
          {getFieldError("ar_answer") && (
            <span className="error-text">{getFieldError("ar_answer")}</span>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddFAQ;
