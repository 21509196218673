import { useSelector } from "react-redux";
import {
  getBasicData,
  getMenuPermissions,
  updateConfig,
} from "../../../Store/Slices/Global";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const useProtectRoute = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.global);
  const getMenus = async () => {
    const dashboardMenus = JSON.parse(localStorage.getItem("menu_permissions"));
    const basicData = JSON.parse(localStorage?.getItem("basicData"));
    if (dashboardMenus) {
      dispatch(
        updateConfig((state) => {
          state.menu_permissions = dashboardMenus;
          state.menu_permissions_status = "fulfilled";
        })
      );
    } else if (localStorage.getItem("USER_ACCESS_TOKEN_INVESTO")) {
      dispatch(
        getMenuPermissions({
          token: localStorage.getItem("USER_ACCESS_TOKEN_INVESTO"),
        })
      );
    }
    if (basicData) {
      dispatch(
        updateConfig((state) => {
          state.basic_data_status = "fulfilled";
          state.basicData = basicData;
        })
      );
    } else if (localStorage?.getItem("USER_ACCESS_TOKEN_INVESTO")) {
      dispatch(
        getBasicData({
          token: localStorage.getItem("USER_ACCESS_TOKEN_INVESTO"),
        })
      );
    }
  };

  useEffect(() => {
    getMenus();
    // eslint-disable-next-line
  }, []);
  return {
    state,
  };
};

export default useProtectRoute;
