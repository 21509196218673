const useDons = ({ formik }) => {
  const handleRemoveField = (index) => {
    const data = formik?.values?.dons?.filter(
      (_, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("dons", data);
  };

  return {
    handleRemoveField,
  };
};

export default useDons;
