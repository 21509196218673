import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  createModal: false,
  isEdit: false,
  itemId: "",
  itemData: "",
  deleteModal: false,
  clearSelection: false,
};

const FAQSlice = createSlice({
  name: "FAQ",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = FAQSlice.actions;
export default FAQSlice.reducer;
