import React from "react";
import useLink from "./useLink";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Style from './user.module.scss'
import Assets from "../../../Assets";

const Link = () => {
  const { isFetching, linksData, handleCopyURL } = useLink();
  return (
    <div>
      {isFetching ? (
        // <p>Loading...</p>
        <div className="form-preloader-wrap">
          <figure className="preloader">
            <img src={Assets.SPINNER} alt="spinner" />
          </figure>
        </div>
      ) : (
        <div className="pro-ps-5">
          <h4 className="pro-mb-6">Completed Links</h4>
          {linksData?.data?.data?.social_media?.map((value, index) => {
            return (
              <div key={index}   className={`${Style.platform_root} pro-pb-5 pro-mb-5`}>
                <div className="pro-d-flex pro-justify-between pro-items-center">
                  <h6 className="pro-ttl h6 pro-mb-4">
                    {value?.stream?.[0]?.toUpperCase() + value?.stream?.slice(1)}
                  </h6>
                  <p className="pro-mb-4 "> Allowed Amount : <strong>{value?.allowed_amount}</strong></p>
                </div>
                {value?.attachment?.map((val, attachIndex) => {
                  return (
                    <>
                    {/* <div key={attachIndex}  className={`pro-d-flex  pro-justify-between pro-p-4 pro-mb-4 pro-gap-4 ${Style.social_dtls}`}>
                      <p>{val?.content_type}</p>
                      {val?.url_or_file === 2 ? (
                        <div>
                          <Image src={val?.thumbnail} />
                        </div>
                      ) : (
                        <p>{val?.url}</p>
                      )}
                      {val?.url_or_file === 1 && (
                        <span
                          className="material-symbols-outlined"
                          onClick={() => handleCopyURL(val?.url)}
                        >
                          content_copy
                        </span>
                      )}
                      <p>{val?.submission_date}</p>
                    </div> */}



                    <div
                        className={`pro-d-flex  pro-justify-between pro-p-4 pro-mb-4 pro-gap-4 ${Style.social_dtls}`}
                        key={attachIndex}
                        >
                        
                        <div className={` ${Style.dtl_col} ${Style.col_label}`}>
                          <p className="pro-mb-2 pro-font-sm pro-fw-medium">
                            {val?.content_type?.[0]?.toUpperCase() +
                              val?.content_type?.slice(1)}
                          </p>
                          {val?.url_or_file === 2 && 
                              <div className="pro-mb-1">
                              <Image src={val?.thumbnail} />
                            </div> 
                          }
                        
                          <p className="pro-mb-0 pro-font-sm pro-fw-medium pro-mt-3">
                            Updated on {val?.url_or_file === 2 && <span className={`pro-mx-4 pro-fw-normal  ${Style.submission_date}`}>{val?.submission_date}</span>}
                          </p>
                        </div>
                        {val?.url_or_file === 1 && (
                          <>
                            <div  className={` ${Style.dtl_col} ${Style.col_info}`}>
                              {val?.url_or_file === 2 ? (
                                <div>
                                  <Image src={val?.thumbnail} />
                                </div>
                              ) : (
                                <p className="pro-mb-0 pro-pnt">
                                   <a  className="pro-btn-link" href={val?.url} target="_blank" rel="noopener noreferrer">{val?.url} </a>
                                </p>
                              )}
                              <p className="pro-mb-0 pro-mt-3">
                                {val?.submission_date}
                              </p>
                            </div>
                            <div  className={` ${Style.dtl_col} ${Style.col_action}`}>
                              {val?.url_or_file === 1 && (
                                <span className="pro-p-1">
                                  <span
                                    onClick={() => handleCopyURL(val?.url)}
                                    className="material-symbols-outlined"
                                  >
                                    content_copy
                                  </span>
                                </span>
                              )}
                            </div>
                        </>
                       )}
                      </div>
                  </>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Link;
