import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  brand,
  useGetBrandDataQuery,
  useUpdateBrandTableHeadDataMutation,
  useUpdateStatusDataMutation,
} from "../../../Store/Queries/Brand";
import { useEffect } from "react";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Brand/brandSlice";
import { IconText, Image } from "@wac-ui-dashboard/wac_component_library";
import { getBrandData } from "./AddBrand/api";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import Style from "./brand.module.scss";
import { useNavigate } from "react-router";
import { getInterestData } from "../../../Store/Slices/Global";

const useBrandList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const brandState = useSelector((state) => state.brand);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const {
    data: brandList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetBrandDataQuery({
    items_per_page: brandState.currentPageSize,
    current_page: brandState.currentPage,
    sort_by: brandState.sortBy,
    sort_order: brandState.sortOrder,
    search: brandState.search,
  });
  const [updateBrandFields] = useUpdateBrandTableHeadDataMutation();
  const [updateStatus] = useUpdateStatusDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = brandList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [brandList]);

  const handleViewLogo = (logo) => {
    dispatch(
      updateConfig((state) => {
        state.logoModal = true;
        state.logo = logo;
      })
    );
  };
  const closeLogoModal = () => {
    dispatch(
      updateConfig((state) => {
        state.logoModal = false;
      })
    );
  };

  const handleChange = (data) => {
    const newStatus = data.status === "Active" ? true : false;
    let params = {
      id: data?._id,
      status: newStatus ? "Inactive" : "Active",
    };
    updateStatus(params);
    dispatch(
      brand.util.updateQueryData(
        "getBrandData",
        {
          items_per_page: brandState.currentPageSize,
          current_page: brandState.currentPage,
          sort_by: brandState.sortBy,
          sort_order: brandState.sortOrder,
          search: brandState.search,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === "Active" ? "Inactive" : "Active",
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              data: newResult,
            },
          };
          return updatedCachedData;
        }
      )
    );
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      name: (feild, data) => {
        return (
          <div className={Style.brand}>
            <div className="pro-avatar">
              <Image
                src={data["logo_thumb_image"]}
                alt="brand-logo"
                width={25}
                height={25}
              />
            </div>
            <p
              className={`pro-mb-0 pro-ms-2 no-wrap pro-pnt ${
                brandList?.data?.permissions?.can_view && "pro-pnt"
              }`}
              onClick={() => {
                if (brandList?.data?.permissions?.can_view) {
                  dispatch(
                    updateConfig((state) => {
                      state.detailsPage.currentPageSize = 10;
                      state.detailsPage.currentPage = 1;
                    })
                  );
                  navigate({
                    pathname: `/brand-details/${data?._id}`,
                  });
                  sessionStorage.setItem("active-user", `${data?._id}`);
                }
              }}
            >
              {data[feild]}
            </p>
          </div>
        );
      },
      logo_thumb_image: (feild, data) =>
        data?.[feild] ? (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewLogo(data?.[feild])}
          />
        ) : (
          ""
        ),
      status: (feild, data) => {
        const status = data[feild] === "Active";
        return (
          <>
            {brandList?.data?.permissions?.can_change_status ? (
              <div className="pro-toggle">
                <div className="pro-toggle-box">
                  <input
                    type="checkbox"
                    role="switch"
                    id="status"
                    checked={status}
                    onChange={() => handleChange(data)}
                  />
                  <span></span>
                </div>
              </div>
            ) : data[feild] === "Active" ? (
              <span className={"badge-success-outline pro-badge"}>
                {data[feild]}
              </span>
            ) : (
              <span className={"badge-warning-outline pro-badge"}>
                {data[feild]}
              </span>
            )}
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (brandState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = brandState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(getInterestData());
    dispatch(
      updateConfig((state) => {
        state.addModal = !state.addModal;
        state.isEdit = false;
        state.logoPreview = "";
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(getInterestData());
    let selected = e?.[0];
    dispatch(
      updateConfig((state) => {
        state.addModal = true;
        state.selectedItem = selected;
        state.isEdit = true;
      })
    );

    getBrandData(selected).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("something went wrong to fetch data");
      }
    });
  };

  return {
    isLoading,
    isFetching,
    brandState,
    tableFields,
    showEditModal,
    brandList,
    paginationOptions,
    closeLogoModal,
    getRow,
    refetch,
    updateBrandFields,
    handleEditAction,
    handleCreateClick,
    handlePagination,
    handleSort,
    handlePageSize,
    handleSearch,
    handleEditColumnsClick,
    handleClearClick,
  };
};

export default useBrandList;
