import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllRoles,
  updateConfig,
} from "../../../../Store/Slices/Roles/rolesSlice";

const useCreateRoleModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.roles);
  useEffect(() => {
    // if (roleState?.allRoles?.length === 0) {
    dispatch(getAllRoles());
    // }
    // eslint-disable-next-line
  }, []);

  const handleRoleClick = (item) => {
    if (item) {
      navigate({
        pathname: "/configure/roles/role-permissions",
        search: `role=${item.id}`,
      });
    } else {
      navigate("/configure/roles/role-permissions");
    }
    dispatch(updateConfig((state) => (state.addModal = false)));
  };

  return {
    roleState,
    handleRoleClick,
  };
};

export default useCreateRoleModal;
