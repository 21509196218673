import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Global";
import countryData from "../../../../../utils/countryCode";
import { useSelector } from "react-redux";
import useValidations from "../../../../../utils/hooks/useValidations";
import { ProfileSubmit } from "../api";
import { toast } from "react-toastify";

const useProfile = () => {
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.global);
  const [showExistingPassword, setShowExistingPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const { validateEmail, validPasswords } = useValidations();

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d{7,14}$/.test(phoneNumber);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      image: "",
      personal_number: "",
      country_code: "",
      old_password: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Email Required";
      }
      if (values?.image) {
        const acceptedTypes = ["image/jpeg", "image/png"];
        const maxFileSize = 5 * 1024 * 1024; // 2MB in bytes

        // Check file type
        if (
          !acceptedTypes.includes(values.image.type) &&
          !values.image.name.endsWith(".jpg") &&
          !values.image.name.endsWith(".jpeg") &&
          !values.image.name.endsWith(".png")
        ) {
          errors.image = "Invalid file format. Only JPEG and PNG allowed.";
        }

        // Check file size
        if (values.image.size > maxFileSize) {
          errors.image = "File too large.Max 5MB allowed.";
        }
      }
      if (!validateEmail(values.email)) {
        errors.email = "*Enter a valid email";
      }
      if (!values?.personal_number) {
        errors.personal_number = "*Phone Number Required";
      } else if (!validatePhoneNumber(values.personal_number)) {
        errors.personal_number = "*Enter a valid phone number (7-14 digits)";
      }
      if (isPasswordChange) {
        const exValidationResults = validPasswords(values.old_password);
        if (
          !exValidationResults.passwordLength ||
          !exValidationResults.uppercasePassword ||
          !exValidationResults.lowercasePassword ||
          !exValidationResults.digitsPassword ||
          !exValidationResults.charsPassword
        ) {
          errors.old_password =
            "*Password must be 8 characters or more and include uppercase, numbers, and symbols.";
        }
        const validationResults = validPasswords(values.password);
        if (
          !validationResults.passwordLength ||
          !validationResults.uppercasePassword ||
          !validationResults.lowercasePassword ||
          !validationResults.digitsPassword ||
          !validationResults.charsPassword
        ) {
          errors.password =
            "*Password must be 8 characters or more and include uppercase, numbers, and symbols.";
        }
        if (values?.old_password && values?.password) {
          if (values?.old_password === values?.password) {
            errors.password = "* New password can't be old password";
          }
        }
      }
      return errors;
    },
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("first_name", globalState?.profileData?.first_name);
      formData.append("last_name", globalState?.profileData?.last_name);
      formData.append("dial_code", values?.country_code?.value);
      formData.append("personal_number", values?.personal_number);
      formData.append("email", values?.email);
      formData.append("is_password_change", isPasswordChange ? 1 : 0);
      if (isPasswordChange) {
        formData.append("old_password", values?.old_password);
        formData.append("password", values?.password);
      }
      if (values?.image) {
        formData.append("has_image", 1);
        formData.append("image", values?.image);
      } else if (globalState?.profileThumb) {
        formData.append("has_image", 1);
      } else {
        formData?.append("has_image", 0);
      }
      ProfileSubmit(formData).then((resp) => {
        if (resp?.data?.code === 200) {
          toast.success("Updated");
          handleCloseModal();
        } else if (resp?.code === 422) {
          let errors = resp?.errors;
          let errorFields = Object.keys(errors);
          errorFields?.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  useEffect(() => {
    if (globalState?.profileData) {
      const countryCode = countryData?.filter(
        (val) => val?.value === globalState?.profileData?.dial_code
      );
      formik?.setFieldValue("email", globalState?.profileData?.email);
      formik?.setFieldValue(
        "personal_number",
        globalState?.profileData?.personal_number
      );
      formik?.setFieldValue("country_code", countryCode?.[0]);
      dispatch(
        updateConfig(
          (state) => (state.profileThumb = globalState?.profileData?.image)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState?.profileData, countryData]);

  const handleShowExistingPassword = () => {
    setShowExistingPassword(!showExistingPassword);
  };

  const handleProfileImageChange = (e) => {
    const image = e?.target?.files?.[0];
    if (image) {
      formik?.setFieldTouched("image", true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageDataUrl = event.target.result;
        dispatch(
          updateConfig((state) => {
            state.profileThumb = imageDataUrl;
            return state;
          })
        );
      };
      reader.readAsDataURL(image);
    }
    formik?.setFieldValue("image", image);
  };

  const handleDeleteImage = () => {
    dispatch(updateConfig((state) => (state.profileThumb = "")));
    formik?.setFieldValue("image", "");
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.profileModal = false)));
  };

  const handlePasswordChange = () => {
    setIsPasswordChange(!isPasswordChange);
  };
  return {
    formik,
    showNewPassword,
    globalState,
    userDetails: globalState?.profileData,
    showExistingPassword,
    countryCodeOptions: countryData,
    isPasswordChange,
    handleCloseModal,
    handlePasswordChange,
    handleShowNewPassword,
    handleDeleteImage,
    handleProfileImageChange,
    handleShowExistingPassword,
  };
};

export default useProfile;
