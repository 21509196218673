
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Admins/adminactivitySlice";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useEffect } from "react";
import { useUpdateAdminactivtiyTableHeadDataMutation,useGetAdminactivtiyDataQuery } from "../../../Store/Queries/Adminactivity";
import { useNavigate } from "react-router-dom";

const useAdminsactivity = () => {
  const dispatch = useDispatch();
  const adminsactivityState = useSelector((state) => state.adminactivity);
  const navigate = useNavigate();
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [updateAdminactivityFields] = useUpdateAdminactivtiyTableHeadDataMutation();

  const {
    data: adminactivtiyList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetAdminactivtiyDataQuery({
    items_per_page: adminsactivityState.currentPageSize,
    current_page: adminsactivityState.currentPage,
    sort_by: adminsactivityState.sortBy,
    sort_order: adminsactivityState.sortOrder,
    search: adminsactivityState.search,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = adminactivtiyList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [adminactivtiyList]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      user: (feild, data) => {
        return (
          <>
            {adminactivtiyList?.data?.permission?.can_view_user ? (
              <p className="pro-mb-0 pro-pnt" onClick={() => {
                navigate({
                  pathname: `/user-details/${data?.user_id}`,
                });
            }}>{data[feild]}</p>
            ) : (
                <p className="pro-mb-0">{data[feild]}</p>
            )}
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (adminsactivityState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = adminsactivityState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  return {
    paginationOptions,
    tableFields,
    adminsactivityState,
    adminactivtiyList,
    isLoading,
    isFetching,
    showEditModal,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleClearClick,
    handlePagination,
    updateAdminactivityFields,
    handleEditColumnsClick,
  };
};

export default useAdminsactivity;
