import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Terms/termsSlice";
import { useFormik } from "formik";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import { updateTerms } from "../api";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import htmlToDraft from "html-to-draftjs";

const useEditTerms = ({ refetch }) => {
  const dispatch = useDispatch();
  const { description, ar_description } = useSelector((state) => state.terms);

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validate: (values) => {
      let errors = {};
      const plainText = values?.description
        ?.getCurrentContent()
        ?.getPlainText();
      if (!plainText?.trim()) {
        errors.description = "*Description cannot be empty";
      }

      const plainTextArabic = values?.ar_description
        ?.getCurrentContent()
        ?.getPlainText();
      if (!plainTextArabic?.trim()) {
        errors.ar_description = "*(الوصف )Description cannot be empty";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const contentState = values.description.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      const plainText = draftToHtml(contentRaw);

      const contentStateArabic = values.ar_description.getCurrentContent();
      const contentRawArabic = convertToRaw(contentStateArabic);
      const plainTextArabic = draftToHtml(contentRawArabic);

      let obj = {
        description: plainText,
        ar_description: plainTextArabic,
      };
      updateTerms(obj).then((response) => {
        if (response?.data?.code === 200) {
          dispatch(
            updateConfig((state) => {
              state.editModal = false;
            })
          );
          toast.success("updated");
          refetch();
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  useEffect(() => {
    const desc = setTimeout(() => {
      const htmlContent = description?.replace(/\\/g, "");
      const sanitizedHtml = DOMPurify?.sanitize(htmlContent);
      const contentBlock = htmlToDraft(sanitizedHtml);
      const contentState = ContentState?.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      //setting up arabic description
      const htmlContentArabic = ar_description?.replace(/\\/g, "");
      const santizedHtmlArabic = DOMPurify?.sanitize(htmlContentArabic);
      const contentBlockArabic = htmlToDraft(santizedHtmlArabic);
      const contentStateArabic = ContentState?.createFromBlockArray(
        contentBlockArabic.contentBlocks
      );
      const editorStateArabic =
        EditorState.createWithContent(contentStateArabic);

      formik.setFieldValue("description", editorState);
      formik?.setFieldValue("ar_description", editorStateArabic);
    }, 1000);
    return () => clearTimeout(desc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, ar_description]);

  const handleContentChange = (value) => {
    formik.setFieldValue("description", value);
  };

  const handleContentArabicChange = (value) => {
    formik.setFieldValue("ar_description", value);
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.editModal = false)));
  };

  return {
    formik,
    handleCloseModal,
    handleContentChange,
    handleContentArabicChange,
  };
};

export default useEditTerms;
