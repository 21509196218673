import React from "react";
import {
  HeadingGroup,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "./policy.module.scss";
import usePolicy from "./usePolicy";
import EditPolicy from "./EditPolicy";

const Policy = () => {
  const { policy, editModal, refetch, handleCloseModal, handleOpenModal } =
    usePolicy();
  const parse = require("html-react-parser");
  const content = policy?.data?.data?.description ?? "";
  const ar_content = policy?.data?.data?.ar_description ?? "";

  return (
    <>
      <HeadingGroup extraClassName={"pro-mb-2"}>
        {policy?.data?.permissions?.can_update && (
          <button
            className="pro-btn pro-btn-primary pro-items-center"
            onClick={handleOpenModal}
          >
            <span className="material-symbols-outlined">edit_square</span>
            <span>Edit</span>
          </button>
        )}
      </HeadingGroup>
      <div className={Style.main_policy}>
        <div className={Style.main_policy_half}>
          <HeadingGroup
            title={"Privacy Policy"}
            extraClassName={`pro-mb-2 pro-mt-3`}
          />
          <div className={` pro-pb-5 ${Style.admin_content_area}`}>
            {parse(content)}
          </div>
        </div>
        <hr />
        <div className={Style.main_policy_half}>
          <HeadingGroup
            title={"سياسة الخصوصية"}
            extraClassName={`pro-mb-2 pro-mt-3 pro-rtl`}
          />
          {!!parse(ar_content) && (
            <div className={` pro-pb-5 pro-rtl ${Style.admin_content_area}`}>
              {parse(ar_content)}
            </div>
          )}
        </div>
      </div>

      <ModalLayout
        centered={false}
        show={editModal}
        handleClose={handleCloseModal}
        title={"Update Privacy Policy"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle={{ root: Style.modal_root }}
      >
        <div className="modal_content_wrap">
          <EditPolicy closeModal={handleCloseModal} refetch={refetch} />
        </div>
      </ModalLayout>
    </>
  );
};

export default Policy;
