import React from "react";
import useChats from "./useChats";
import Style from "./chat.module.scss";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../../Assets";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from "html-react-parser";

const Chat = ({ openedChatStatus }) => {
  const {
    formik,
    supportState,
    lastElement,
    handleClear,
    handleContentChange,
    containsHTML,
  } = useChats();

  return (
    <div className="row">
      <div className="col-12 pro-mb-4 ">
        <div
          className={
            supportState?.chats?.[supportState?.chatId]?.language === "ar"
              ? "input wrap pro-rtl"
              : "input-wrap"
          }
        >
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Add new message
          </label>
          <Editor
            wrapperClassName={Style.pro_editor_wrapper}
            editorClassName={`${Style.pro_editor_main} ${supportState?.chats?.[supportState?.chatId]
              ?.language === "ar"
              ? `${Style.chats_arb}`
              : `${Style.chats_eng}`}`}
            toolbarClassName={Style.pro_editor_toolbar}
            editorState={formik.values.message}
            readOnly={false}
            onEditorStateChange={handleContentChange}
            toolbar={{
              options: ["inline"],
              inline: {
                inDropdown: false,
                options: ["bold", "italic", "underline"],
                bold: { className: Style.bold_button },
                italic: { className: Style.italic_button },
                underline: { className: Style.underline_button },
              },
            }}
          />
          {formik.touched.message && formik.errors.message && (
            <span className="error-text">{formik.errors.message}</span>
          )}
        </div>
        <div
          className={
            supportState?.chats?.[supportState?.chatId]?.language === "ar"
              ? `col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto pro-rtl ${"offcanvas-footer-sticky-btn"}`
              : `col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto  ${"offcanvas-footer-sticky-btn"}`
          }
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleClear}
            type="button"
          >
            Clear
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            onClick={formik?.handleSubmit}
            type="submit"
            disabled={openedChatStatus === "Solved"}
          >
            {formik?.isSubmitting ? (
              <span className="btn-spinner">
                <img src={Assets.SPINNER} alt="spinner" />
              </span>
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </div>
      {supportState?.chatStatus === "pending" &&
      Object?.keys(supportState?.chats)?.length === 0 ? (
        <p>Loading</p>
      ) : (
        <div className={`pro-rtl ${Style.followUp_list_wrap}`}>
          {supportState?.chats?.[supportState?.chatId]?.messages?.map(
            (value, index) => {
              let header = value?.message_owner?.concat(
                `: ${value?.time}, ${value?.created_at}`
              );
              return (
                <div
                  className={
                    supportState?.chats?.[supportState?.chatId]?.language ===
                    "ar"
                      ? `${Style.item_arabic} `
                      : `${Style.item} `
                  }
                  key={index}
                  ref={
                    index ===
                    supportState?.chats?.[supportState?.chatId]?.messages
                      ?.length -
                      5
                      ? lastElement
                      : false || null
                  }
                >
                  <div className={`${Style.list_item}`}>
                    <p
                      className={
                        supportState?.chats?.[supportState?.chatId]
                          ?.language === "ar"
                          ? `pro-font-sm pro-fw-medium pro-mb-0 pro-rtl ${Style.date}`
                          : `pro-font-sm pro-fw-medium pro-mb-0 pro-ltr${Style.date}`
                      }
                    >
                      {header}
                    </p>
                    <div
                      className={`${Style.list_item_wrap} ${
                        value?.message_type === "admin" ? Style.admin : ""
                      } pro-px-5 pro-py-4 `}
                    >
                      <div
                        className={
                          supportState?.chats?.[supportState?.chatId]
                            ?.language === "ar"
                            ? `${Style.chats_arb}`
                            : `${Style.chats_eng}`
                        }
                      >
                        {containsHTML(value?.message) ? parse(value?.message) : <p>{value?.message}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

export default Chat;
