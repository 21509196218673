const usePostItem = ({ formik }) => {
  const handleRemoveField = (index) => {
    const data = formik?.values?.post?.filter(
      (_, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("post", data);
  };
  return {
    handleRemoveField,
  };
};

export default usePostItem;
