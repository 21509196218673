import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/InterestChange/interestChangeSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  useGetInterestChangeDataQuery,
  useUpdateInterestChangeTableHeadDataMutation,
} from "../../../Store/Queries/Users";
import Select from "react-select";
import { changeInterestStatus } from "./api";
import { toast } from "react-toastify";
import Assets from "../../../Assets";

const useInterestChange = () => {
  const dispatch = useDispatch();
  const { tableFields, showEditModal,currentUrl} = useSelector((state) => state.global);
  const interestChangeState = useSelector((state) => state.interestChange);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const [updateFields] = useUpdateInterestChangeTableHeadDataMutation();

  const {
    data: interestChangeList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetInterestChangeDataQuery({
    items_per_page: interestChangeState.currentPageSize,
    current_page: interestChangeState.currentPage,
    sort_by: interestChangeState.sortBy,
    sort_order: interestChangeState.sortOrder,
    search: interestChangeState.search,
    status:
      activeTab === "pending"
        ? 1
        : activeTab === "approved"
        ? 2
        : activeTab === "rejected"
        ? 3
        : 1,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const navigation = [
    {
      label: "Pending",
      title: "Pending",
      link: `/user/interest-change?activeTab=pending`,
      active: activeTab === "pending",
    },
    {
      label: "Approved",
      title: "Approved",
      link: `/user/interest-change?activeTab=approved`,
      active: activeTab === "approved",
    },
    {
      label: "Rejected",
      title: "Rejected",
      link: `/user/interest-change?activeTab=rejected`,
      active: activeTab === "rejected",
    },
  ];

  useEffect(() => {
    //while swithing tabs,the current page will be 1 and page size will be 10
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = interestChangeList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [isFetching]);

  useEffect(() => {
    if (currentUrl?.includes("user-details")) {
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
        })
      );
      dispatch(
        globalUpdateConfig((state) => {
         state.currentUrl=""
        })
      );
    }else{
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
          state.currentPage = 1;
          state.currentPageSize = 10;
        })
      );
    }
    const clearSelectionTimeout = setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }, 200);

    return () => clearTimeout(clearSelectionTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleStatusChange = (val, data) => {
    const id = data?._id;
    dispatch(updateConfig((state) => (state.rejectId = id)));
    const status =
      val?.value === "Approved" ? 2 : val?.value === "Rejected" ? 3 : 1;
    if (status === 2) {
      dispatch(updateConfig((state) => (state.isPending = true)));
      let body = {
        request_id: id,
        status: status,
      };
      changeInterestStatus(body).then((response) => {
        if (response?.data?.code === 200) {
          dispatch(
            updateConfig((state) => {
              state.rejectModal = false;
              state.isPending = false;
            })
          );
          toast.success("Approved");
          refetch();
        } else {
          toast.error("Something went wrong");
          dispatch(updateConfig((state) => (state.isPending = false)));
        }
      });
    } else {
      dispatch(
        updateConfig((state) => {
          state.rejectModal = true;
        })
      );
    }
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) => {
        const options = [
          {
            label: "Approved",
            value: "Approved",
          },
          {
            label: "Rejected",
            value: "Rejected",
          },
        ];
        const status = data[feild];
        const statusObj = {
          label: data[feild],
          value: data[feild],
        };
        return (
          <>
            {status === "Pending" ? (
              interestChangeList?.data?.permissions?.can_change_status ? (
                <>
                  {interestChangeState?.isPending &&
                  interestChangeState?.rejectId === data?._id ? (
                    <span span className="btn-spinner">
                      <img src={Assets.SPINNER} alt="spinner" />
                    </span>
                  ) : (
                    <>
                      <Select
                        id="status"
                        placeholder={"Select"}
                        className={`pro-input lg`}
                        classNamePrefix="pro-input"
                        name="status"
                        options={options}
                        value={statusObj}
                        onChange={(value) => handleStatusChange(value, data)}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </>
                  )}
                </>
              ) : (
                <span className={"badge-warning-outline pro-badge"}>
                  {status}
                </span>
              )
            ) : status === "Approved" ? (
              <span className={"badge-success-outline pro-badge"}>
                {status}
              </span>
            ) : status === "Rejected" ? (
              <span className={"badge-danger-outline pro-badge"}>{status}</span>
            ) : (
              <span className={"badge-grey-outline pro-badge"}>{status}</span>
            )}
          </>
        );
      },
      name: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            interestChangeList?.data?.permissions?.can_view_user
              ? `pro-pnt text-truncate `
              : ""
          }`}
          onClick={() => {
            if (interestChangeList?.data?.permissions?.can_view_user) {
              navigate({
                pathname: `/user-details/${data?.user_id}active="${activeTab}"&type="interest-change"`,
              });
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (interestChangeState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            interestChangeState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {dispatch(updateConfig((state) => (state.search = "")));};

  const handleCloseRejecetModal = () => {
    dispatch(updateConfig((state) => (state.rejectModal = false)));
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    interestChangeList,
    interestChangeState,
    tabNavs: navigation,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    updateFields,
    handlePageSize,
    handleClearClick,
    handlePagination,
    handleEditColumnsClick,
    handleCloseRejecetModal,
  };
};

export default useInterestChange;
