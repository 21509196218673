import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import useRoutes from "../../../Routes/useRoutes";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
const useCommonLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { menuDrawer } = useSelector((state) => state.global);
  const globalState = useSelector((state) => state.global);

  const {
    allowedConfigureComponents,
    allowedDashBoardComponents,
    allowedUserComponents,
    allowedSupportComponents,
    allowedReportComponents,
    allowedBrandComponents,
    allowedUserCategoryComponents,
    allowedCampainComponents,
    allowedSettingsComponents,
    allowedPaymentsComponents,
    allowedCMSComponents,
  } = useRoutes();

  const { checkIfActiveRoute } = useRouteUtils();

  const navigations = [
    {
      label: "Dashboard",
      link: `/${allowedDashBoardComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Campaign",
      link: `/${allowedCampainComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/campaign"),
    },

    {
      label: "User",
      link: `/${allowedUserComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/user"),
    },
    {
      label: "Support",
      link: `/${allowedSupportComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/support"),
    },
    {
      label: "Report",
      link: `/${allowedReportComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/report"),
    },
    {
      label: "Brand",
      link: `/${allowedBrandComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/brand"),
    },
    {
      label: "Payments",
      link: `/${allowedPaymentsComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/payments"),
    },
    {
      label: "CMS",
      link: `/${allowedCMSComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/CMS"),
    },
    {
      label: "User Category",
      link: `/${allowedUserCategoryComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/user-category"),
    },
    {
      label: "Configure",
      link: `/${allowedConfigureComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/configure"),
    },
    {
      label: "Settings",
      link: `/${allowedSettingsComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/Settings"),
    },
  ];
  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };
  const topMenuPermission = useMemo(() => {
    let menus = globalState.menu_permissions?.menu_permissions?.flatMap?.(
      (menu) => [menu?.menu_name, "Settings"]
    );
    return navigations
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    menuDrawer,
    globalState,
    handleGoBack,
  };
};

export default useCommonLayout;
