import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editModal: false,
  desciption: "",
  ar_description: "",
};

const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = policySlice.actions;
export default policySlice.reducer;
