import { useFormik } from "formik";
import countryData from "../../../../utils/countryCode";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Admins/adminsSlice";
import { useSelector } from "react-redux";
import { createAdmin, updateAdmin } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const useAddAdmin = ({ refetch }) => {
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const dispatch = useDispatch();
  const { isEdit, imagePreview, itemData, selectedItem } = useSelector(
    (state) => state.admins
  );
  const { basicData } = useSelector((state) => state.global);
  const initialValues = {
    image: "",
    first_name: "",
    last_name: "",
    country_code: "",
    personal_number: "",
    email: "",
    role_type: "",
    password: "",
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().required("Enter First Name"),
    last_name: Yup.string().required("Enter Last Name"),
    personal_number: Yup.string().required("Enter Conatact Number"),
    password:
      !isEdit || (isEdit && isPasswordChange)
        ? Yup.string()
            .required("Enter Password")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              "Password must be 8+ characters with uppercase, lowercase, digit, and special character."
            )
        : "",
    role_type: Yup.object().required("Select Role Type"),
    email: Yup.string().required("Enter Email").email("Email is Inavlid"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let data = {
        image: values?.image,
        first_name: values?.first_name,
        last_name: values?.last_name,
        dial_code: values?.country_code?.value,
        personal_number: values?.personal_number,
        email: values?.email,
        role_id: values?.role_type?._id,
        password: values?.password,
      };
      let formData = new FormData();
      for (let key in data) {
        if (data[key] !== undefined && data[key] !== null && data[key] !== "") {
          formData.append(key, data[key]);
        }
      }
      if (isEdit) {
        formData.append("subadmin_id", selectedItem);
        if (values?.image === "") {
          formData?.delete("image");
        }
        if (imagePreview) {
          formData?.append("has_image", 1);
        } else {
          formData?.append("has_image", 0);
        }
        formData.append("is_password_change", isPasswordChange ? 1 : 0);
        if (isPasswordChange) {
          formData?.append("password", values?.password);
        }
        updateAdmin(formData).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Updated");
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        createAdmin(formData).then((response) => {
          if (response?.data?.code === 200) {
            handleCloseModal();
            toast.success("Admin added succesfully");
            refetch();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.addModal = false;
        state.imagePreview = "";
      })
    );
  };

  const handleImageChange = (event) => {
    const selectedImage = event?.target?.files[0];
    if (selectedImage) {
      formik.setFieldValue("image", selectedImage);
      const file = selectedImage;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.imagePreview = "";
      })
    );
    formik.setFieldValue("image", "");
  };

  useEffect(() => {
    if (!isEdit) {
      formik?.setFieldValue("country_code", {
        name: "United Arab Emirates",
        code: "AE",
        label: "+971",
        value: "+971",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    if (isEdit) {
      Object.keys(itemData || {}).forEach((key) => {
        if (key === "image") {
          if (itemData?.image) {
            dispatch(
              updateConfig((state) => (state.imagePreview = itemData?.image))
            );
          } else if (!itemData.image) {
            dispatch(updateConfig((state) => (state.imagePreview = "")));
          }
        }
        if (key === "first_name") {
          formik.setFieldValue("first_name", itemData?.[key]);
        } else if (key === "last_name") {
          formik.setFieldValue("last_name", itemData?.[key]);
        } else if (key === "email") {
          formik.setFieldValue("email", itemData?.[key]);
        } else if (key === "role_id") {
          const role = basicData?.admin_role?.filter(
            (value) => value?._id === itemData?.[key]
          );
          formik?.setFieldValue("role_type", role?.[0]);
        } else if (key === "dial_code") {
          const dailCode = countryData?.filter(
            (val) => val?.value === itemData?.[key]
          );
          formik?.setFieldValue("country_code", dailCode?.[0]);
        } else {
          if (key !== "image") {
            formik.setFieldValue(key, itemData?.[key]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, itemData]);

  const handlePasswordChange = () => {
    setIsPasswordChange(!isPasswordChange);
  };

  return {
    formik,
    isEdit,
    basicData,
    imagePreview,
    isPasswordChange,
    countryCodeOptions: countryData,
    getFieldError,
    handleCloseModal,
    handleImageChange,
    handleImageRemove,
    handlePasswordChange,
  };
};

export default useAddAdmin;
