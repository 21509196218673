import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Report/reportSlice";
import * as Yup from "yup";

const useFilterReport = ({ refetch }) => {
  const dispatch = useDispatch();
  const { basicData } = useSelector((state) => state.global);
  const reportState = useSelector((state) => state.report);
  const validationSchema = Yup.object({
    status: Yup.object(),
  });

  const formik = useFormik({
    initialValues: {
        status: reportState?.status,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateConfig((state) => {
          state.status =values?.status ? values?.status:"";
          state.currentPage =1;
          state.currentPageSize =10;
        })
      );
      handleCloseModal();
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };

  return {
    formik,
    getFieldError,
    handleCloseModal,
    basicData,
  };
};

export default useFilterReport;
