import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../API";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&${getParams("items_per_page")}&${getParams(
            "search"
          )}&${getParams("start_date")}${getParams("end_date")}${getParams(
            "campaign_id"
          )}${getParams("user_campaign_id")}&${getParams(
            "status"
          )}current_page=${params?.page || "1"}`,
          body
        );

        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&${getParams("start_date")}${getParams("end_date")}items_per_page=${
            params?.items_per_page || "10"
          }&${getParams("search")}&${getParams("user_campaign_id")}&${getParams(
            "social_media"
          )}current_page=${params?.page || ""}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const campaign = createApi({
  reducerPath: "campaignApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "campaignCreate",
    "campaignApproval",
    "linkdata",
    "contentVerification",
  ],
  endpoints: (builder) => ({
    getCampaignCreateData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/campaign/list`,
      }),
      providesTags: ["campaignCreate"],
    }),
    getAllBrandsData: builder.query({
      query: (body) => ({
        method: "get",
        body,
        endpoint: `/brands`,
      }),
    }),
    getLinkTabData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/campaign/process`,
      }),
      providesTags: ["linkdata"],
    }),
    getLinkData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/campaign/verify-link-list`,
      }),
      keepUnusedDataFor: 1,
    }),
    getPaymentDetailsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/campaign/amount-add-before-details`,
      }),
    }),
    getCampaignDetailsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/campaign/${values.campaign_id}/view`,
      }),
    }),
    getCampaignDetailsTableData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/campaign/user-list`,
      }),
    }),
    getCampaignDashboardData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/campaign/request-list-count`,
      }),
    }),
    updateCampaignStatusData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/campaign/status-change`,
      }),
    }),
    getCampaignApprovalData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/campaign/request-list`,
      }),
      providesTags: ["campaignApproval"],
    }),

    updateCampaignCreateTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/field-update`,
      }),
      invalidatesTags: ["campaignCreate"],
    }),
    updateCampaignApprovalTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/field-update`,
      }),
      invalidatesTags: ["campaignApproval"],
    }),

    //content verification starts here
    getContentVerificationData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/campaign/content-list`,
      }),
      providesTags: ["contentVerification"],
    }),
    updateConentVerificationTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/field-update`,
      }),
      invalidatesTags: ["contentVerification"],
    }),
    //content verification ends here
    //report starts here
    getReportData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/report/list`,
      }),
      providesTags: ["report"],
    }),
    updateReportTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/field-update`,
      }),
      invalidatesTags: ["report"],
    }),
    //report ends here
  }),
});

export const {
  useGetLinkDataQuery,
  useGetReportDataQuery,
  useGetLinkTabDataQuery,
  useGetAllBrandsDataQuery,
  useGetPaymentDetailsDataQuery,
  useGetCampaignCreateDataQuery,
  useGetCampaignDetailsDataQuery,
  useGetCampaignApprovalDataQuery,
  useGetCampaignDashboardDataQuery,
  useUpdateCampaignStatusDataMutation,
  useGetContentVerificationDataQuery,
  useGetCampaignDetailsTableDataQuery,
  useUpdateCampaignCreateTableHeadDataMutation,
  useUpdateCampaignApprovalTableHeadDataMutation,
  useUpdateConentVerificationTableHeadDataMutation,
  useUpdateReportTableHeadDataMutation,
} = campaign;
