import { useSelector } from "react-redux";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";

const useConfigure = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Admins",
      label: "Admins",
      link: "/configure/admins",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">admin_panel_settings</span>
      ),
      active: checkIfActiveRoute("/configure/admins"),
    },
    {
      title: "Roles",
      label: "Roles",
      link: "/configure/roles",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">settings_account_box</span>
      ),
      active: checkIfActiveRoute("/configure/roles"),
    },
    {
      title: "Admin activity",
      label: "Admin activity",
      link: "/configure/admin-activity",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4"> deployed_code_account</span>
      ),
      active: checkIfActiveRoute("/configure/admin-activity"),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.menu_permissions?.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.label);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default useConfigure;
