import { useEffect, useState } from "react";
import { useGetLinkDataQuery } from "../../../../../Store/Queries/Campaign";
import { useSelector } from "react-redux";
import { submitLink } from "../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Campaign/Creation/CreationSlice";

const useLinkSection = ({ refetch ,listRefetch}) => {
  const { verifyItemId } = useSelector((state) => state.campaignCreate);
  const { data: linkData, isFetching } = useGetLinkDataQuery({
    user_campaign_id: verifyItemId,
  });

  const [remark, setRemark] = useState("");
  const [ar_remark, setArRemark] = useState("");
  const [errors, setErrors] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setRemark(linkData?.data?.data?.remark ?? "");
    setArRemark(linkData?.data?.data?.ar_remark ?? "");
  }, [linkData]);

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyLink = (copyText) => {
    copyTextToClipboard(copyText).then(() => {
      toast.success("Copied To Clipboard");
    });
  };

  const handleRemarkChange = (e) => {
    setErrors("");
    setRemark(e?.target?.value);
  };

  const handleArabicRemarkChange = (e) => {
    setErrors("");
    setArRemark(e?.target?.value);
  };

  const handleSumbit = (status) => {
    if (!remark || remark?.length < 1) {
      setErrors("Enter Remarks");
    } else {
      let data = {
        user_campaign_id: verifyItemId,
        status,
        remark,
        ar_remark,
      };
      submitLink(data).then((response) => {
        if (response?.data?.code === 200) {
          if(response?.data?.message==="Verified successfully"){
            toast.success("Success");
            refetch();
            dispatch(updateConfig((state) => (state.verifyTab = "Payment")));
          }else{
            listRefetch()
            refetch();
            dispatch(updateConfig((state) => (state.verifyModal = false)));
            toast.success("Rejected successfully");
          }
        } else {
          toast.error("Something Went Wrong");
        }
      });
    }
  };

  const handleApproveClick = () => {
    handleSumbit(1);
  };
  const handleRejectClick = () => {
    handleSumbit(0);
  };

  const handleModalClose = () => {
    dispatch(updateConfig((state) => (state.verifyModal = false)));
  };
  return {
    remark,
    errors,
    linkData,
    isFetching,
    ar_remark,
    handleArabicRemarkChange,
    handleCopyLink,
    handleModalClose,
    handleRejectClick,
    handleApproveClick,
    handleRemarkChange,
  };
};

export default useLinkSection;
