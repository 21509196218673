import React from "react";
import { Link, Outlet } from "react-router-dom";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";
import {
  Header,
  Image,
  MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import useCommonLayout from "../../../Pages/Layouts/CommonLayout/useCommonLayout";
import Assets from "../../../Assets";
import HeaderActions from "../../../Pages/Layouts/CommonLayout/HeaderActions";
import Style from "../../../Pages/Layouts/CommonLayout/commonLayout.module.scss";
import campaignStyle from "./campaignDetailsLayout.module.scss";
import CampaignLayoutSidebarShimmer from "../../Global/Shimmers/CampaignLayoutSidebarShimmer";
import useCampaignDetailsLayout from "./useCampaignDetailsLayout";
import SocialAccordian from "../../Global/SocialAccordian";
import Accordian from "../../Global/Accordian";

const CampaignDetailsLayout = () => {
  const {
    brand,
    isFetching,
    campaignData,
    activeCampaign,
    basicDetails,
    showAllReason,
    showAllDesc,
    handleReadMoreDesc,
    handleReadMore,
    handleBackClick,
  } = useCampaignDetailsLayout();
  const { navigations, globalState } = useCommonLayout();
  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${campaignStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${campaignStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(campaignData?.data ?? {})?.length === 0 ? (
              <>
                <CampaignLayoutSidebarShimmer />
              </>
            ) : (
              <>
                <ProfileSideBar
                  extraClassName={campaignStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-5 pro-pt-5`}>
                    <div
                      className={`pro-py-3 pro-px-2 pro-border-bottom pro-mb-4`}
                    >
                      <button
                        className="pro-back-btn pro-items-center pro-d-flex"
                        onClick={handleBackClick}
                      >
                        <span className="icon">
                          <span className="material-symbols-outlined x4">
                            chevron_left
                          </span>
                        </span>
                        <span className="title">
                          <b>Back to Campaign</b>
                        </span>
                      </button>
                    </div>
                    <div className={`${campaignStyle.left_sidebar_sticky_top}`}>
                      <div className={campaignStyle.profile}>
                        <Image
                          src={`${
                            campaignData?.data?.image ?? Assets.CAMPAIGN
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>

                      <SimpleTextDetails
                        title={`${campaignData?.data?.title ?? ""}`}
                        extraClassNames={`pro-mt-4 pro-mb-2 ${campaignStyle.profile_name_wrap}`}
                      />
                    </div>
                    <div>
                      <div
                        className={`${campaignStyle.brand_container} pro-d-flex pro-items-center pro-gap-3 pro-pb-4 pro-mb-4 pro-border-bottom`}
                      >
                        <Image
                          src={campaignData?.data?.brand_logo}
                          alt={"brand_logo"}
                          width={34}
                          height={34}
                          propStyle={{ root: campaignStyle.image_root }}
                        />
                        <p className="pro-mb-0">{brand?.[0]?.brand_name}</p>
                      </div>
                      <div className="pro-w-100">
                        {campaignData?.data?.description && (
                          <>
                            {campaignData?.data?.description?.length > 75 &&
                            !showAllDesc ? (
                              <p>
                                {campaignData?.data?.description?.slice(0, 75) +
                                  "..."}
                                <span
                                  className="pro-pnt"
                                  onClick={handleReadMoreDesc}
                                >
                                  Read More
                                </span>
                              </p>
                            ) : (
                              <p>{campaignData?.data?.description}</p>
                            )}
                          </>
                        )}
                      </div>
                      <div className="pro-d-flex pro-flex-wrap pro-gap-2">
                        {campaignData?.data?.interest?.map((value, index) => {
                          return (
                            <span
                              className="pro-badge badge-primary-light"
                              key={index}
                            >
                              {value}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                    <div className="pro-mt-4">
                      <div className="pro-mb-2">
                        {campaignData?.data?.status === "Active" ? (
                          <span className={"badge-success-outline pro-badge"}>
                            {campaignData?.data?.status}
                          </span>
                        ) : campaignData?.data?.status === "Pending" ? (
                          <span className={"badge-warning-outline pro-badge"}>
                            {campaignData?.data?.status}
                          </span>
                        ) : campaignData?.data?.status === "Inactive" ? (
                          <span className={"badge-pending-outline pro-badge"}>
                            {campaignData?.data?.status}
                          </span>
                        ) : campaignData?.data?.status === "Completed" ? (
                          <span className={"badge-success-outline pro-badge"}>
                            {campaignData?.data?.status}
                          </span>
                        ) : campaignData?.data?.status === "Stopped" ? (
                          <span className={"badge-danger-outline pro-badge"}>
                            {campaignData?.data?.status}
                          </span>
                        ) : (
                          <span className={"badge-grey-outline pro-badge"}>
                            {campaignData?.data?.status}
                          </span>
                        )}
                      </div>
                      {campaignData?.data?.status === "Stopped" && (
                        <div>
                          {campaignData?.data?.reason?.length > 75 &&
                          !showAllReason ? (
                            <p>
                              {"Reason : " +
                                campaignData?.data?.reason?.slice(0, 75) +
                                "..."}
                              <span
                                className="pro-pnt"
                                onClick={handleReadMore}
                              >
                                Read More
                              </span>
                            </p>
                          ) : (
                            <p>{"Reason : " + campaignData?.data?.reason}</p>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      className={`${campaignStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <MultiColumnTable
                        title={"Basic Details"}
                        data={basicDetails}
                        extraClassNames={`pro-mb-5`}
                      />
                      <SocialAccordian
                        socialItems={campaignData?.data?.social_media ?? {}}
                        isPost={campaignData?.data?.payment_type === 0}
                      />
                      <Accordian
                        task={campaignData?.data?.task ?? []}
                        caption={campaignData?.data?.caption_ideas ?? ""}
                        hashtag={campaignData?.data?.hashtag ??""}
                        brand_mention={campaignData?.data?.brand_mention ?? ""}
                        dos={campaignData?.data?.dos ?? []}
                        donots={campaignData?.data?.dos ?? []}
                       />
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          {/* main container */}
          <div className={`${campaignStyle.main_container}`}>
            <div className={`${campaignStyle.main_container_inner}`}>
              <Outlet context={{ activeCampaign }} />
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default CampaignDetailsLayout;
