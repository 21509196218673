import { getAxiosInstance } from "../../../API";

export const createTemplate = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/notification_template/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateTemplate = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/notification_template/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const viewTemplate = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/notification_template/${id}/view`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/notification_template/status-change`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteTemplate = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/notification_template/delete?id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
