import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Notifications/notificationSlice";
import * as Yup from "yup";

const useFilterNotification = ({ refetch }) => {
  const dispatch = useDispatch();
  const { basicData } = useSelector((state) => state.global);
  const notificationState = useSelector((state) => state.notifications);
  const validationSchema = Yup.object({
    notification_type: Yup.object(),
  });

  const formik = useFormik({
    initialValues: {
      notification_type: notificationState?.notification_type,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateConfig((state) => {
          state.notification_type =
            values?.notification_type ? values?.notification_type:"";
          state.currentPage =1;
          state.currentPageSize =10;
        })
      );
      handleCloseModal();
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };

  return {
    formik,
    getFieldError,
    handleCloseModal,
    basicData,
  };
};

export default useFilterNotification;
