import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useSocialConnectForm from "./useSocialConnectForm";
import Style from "./form.module.scss";

const SocialConnectForm = ({ refetch, headerData, statusFetching }) => {
  const { tabs, activeTab, renderTabContent, handleTabClick, handleSubmit } =
    useSocialConnectForm({ refetch, headerData, statusFetching });
  return (
    <div className="pro-h-100 pro-d-flex pro-flex-column">
      <FormTabs
        propStyle={{ root: Style.root }}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
      />
      <form className="pro-h-100" onSubmit={handleSubmit}>
        {renderTabContent()}
      </form>
    </div>
  );
};

export default SocialConnectForm;
