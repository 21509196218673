import { useNavigate, useParams } from "react-router";
import {
  useGetBrandDetailsCountDataQuery,
  useGetBrandDetailsTableDataQuery,
  useUpdateBrandDetailsTableHeadDataMutation,
} from "../../../../Store/Queries/Brand";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../Store/Slices/Global";
import { useSelector } from "react-redux";
import SocialIcons from "../../../Global/SocialIcons";
import { updateConfig } from "../../../../Store/Slices/Brand/brandSlice";
import Style from "./brands_details.module.scss";
import { getFormatedDate } from "../../../../utils/Table";
import { subDays } from "date-fns";
import { brandsInnerTableExport } from "../../api";
import { toast } from "react-toastify";
// import { useHistory } from "react-router-dom";

const useBrandsDetail = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const navigate = useNavigate();
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 60),
    endDate: subDays(new Date(), -60),
    key: "selection",
  });

  const dispatch = useDispatch();
  const { brandID } = useParams();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const brandCreateState = useSelector((state) => state.brand);
  const activeBrand = brandID ?? sessionStorage.getItem("active-user");
  const {
    data: brandCountData,
    isFetching: reportFetching,
    refetch,
  } = useGetBrandDetailsCountDataQuery({
    brand_id: activeBrand,
  });
  const {
    data: brandDetailsTableList,
    isFetching: tableFetching,
    isLoading,
  } = useGetBrandDetailsTableDataQuery({
    brand_id: activeBrand,
    items_per_page: brandCreateState?.detailsPage?.currentPageSize,
    current_page: brandCreateState?.detailsPage?.currentPage,
    sort_by: brandCreateState?.detailsPage?.sortBy,
    sort_order: brandCreateState?.detailsPage?.sortOrder,
    start_date: brandCreateState?.detailsPage?.startDate
      ? getFormatedDate(brandCreateState?.detailsPage?.startDate)
      : "",
    end_date: brandCreateState?.detailsPage?.endDate
      ? getFormatedDate(brandCreateState?.detailsPage?.endDate)
      : "",
    search: brandCreateState?.detailsPage?.search,
  });

  const [updateBrandDetailsTableFields] =
    useUpdateBrandDetailsTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = brandDetailsTableList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [brandDetailsTableList]);

  const headerLabel = {
    total_campaigns: "Total Campaigns",
    live_campaigns: "Live Campaigns",
    overall_cost: "Overall Cost",
  };

  const HeaderValue = {
    total_campaigns: `${brandCountData?.data?.total_campaigns ?? "Unavalable"}`,
    live_campaigns: `${
      `${brandCountData?.data?.live_campaigns}` ?? "Unavalable"
    }`,
    overall_cost: `${
      `AED ${brandCountData?.data?.total_budget}` ?? "Unavalable"
    }`,
  };

  const headerDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      title: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            brandDetailsTableList?.data?.permissions?.can_view ? "pro-pnt" : ""
          }`}
          onClick={() => {
            if (brandDetailsTableList?.data?.permissions?.can_view) {
              navigate({
                pathname: `/campaign-details/${data?._id}activeTab="all"`,
              });
              sessionStorage.setItem("active-campaign", `${data?._id}`);
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
      status: (feild, data) => {
        const status = data[feild];
        return (
          <>
            {status === "Active" ? (
              <span className={"badge-success-outline pro-badge"}>
                {status}
              </span>
            ) : status === "Pending" ? (
              <span className={"badge-warning-outline pro-badge"}>
                {status}
              </span>
            ) : status === "Inactive" ? (
              <span className={"badge-pending-outline pro-badge"}>
                {status}
              </span>
            ) : status === "Completed" ? (
              <span className={"badge-success-outline pro-badge"}>
                {status}
              </span>
            ) : status === "Stopped" ? (
              <span className={"badge-danger-outline pro-badge"}>{status}</span>
            ) : (
              <span className={"badge-grey-outline pro-badge"}>{status}</span>
            )}
          </>
        );
      },
      budget: (feild, data) => (
        <div>
          {data[feild]?.total && (
            <p className="pro-mb-1 no-wrap ">Total: {data[feild]?.total}</p>
          )}
          {data[feild]?.balance && (
            <p className="pro-mb-0 no-wrap ">Balance: {data[feild]?.balance}</p>
          )}
        </div>
      ),
      is_live: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? "Yes" : "No"}</p>
      ),
      social_media: (feild, data) => <SocialIcons icons={data[feild]} />,
      interest: (field, data) => {
        const interests = data[field];
        if (interests?.length > 2) {
          const displayedInterests = interests.slice(0, 2); // Take only the first two items
          return (
            <p className="pro-mb-0">
              {displayedInterests.map((value, index) => (
                <span
                  key={index}
                  className={`pro-badge badge-primary-light ${Style.interests}`}
                >
                  {value}
                </span>
              ))}
              <span
                className={`pro-badge badge-primary-light ${Style.interests}`}
              >
                ...
              </span>
            </p>
          );
        } else {
          return (
            <p className="pro-mb-0">
              {interests?.map((value, index) => (
                <span
                  key={index}
                  className={`pro-badge badge-primary-light ${Style.interests}`}
                >
                  {value}
                </span>
              ))}
            </p>
          );
        }
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.detailsPage.currentPageSize = page_size;
        state.detailsPage.budgetcurrentPage = 1;
        state.detailsPage.currentPage = 1;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.detailsPage.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (brandCreateState.detailsPage.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.detailsPage.currentPage = 1;
          state.detailsPage.sortOrder =
            brandCreateState.detailsPage.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.detailsPage.currentPage = 1;
          state.detailsPage.sortBy = label;
          state.detailsPage.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.detailsPage.search = "";
        state.detailsPage.startDate = subDays(new Date(), 60);
        state.detailsPage.endDate = subDays(new Date(), -60);
      })
    );
    setInitialDate({
      startDate: subDays(new Date(), 60),
      endDate: subDays(new Date(), -60),
      key: "selection",
    });
  };

  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.detailsPage.startDate = new Date(date.startDate);
        state.detailsPage.endDate = new Date(date.endDate);
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.detailsPage.search = e.target.value;
        state.detailsPage.currentPage = 1;
      })
    );
  };

  const handleExportTable = () => {
    let data = {
      brand_id: activeBrand,
      start_date: brandCreateState?.detailsPage?.startDate
        ? getFormatedDate(brandCreateState?.detailsPage?.startDate)
        : "",
      end_date: brandCreateState?.detailsPage?.endDate
        ? getFormatedDate(brandCreateState?.detailsPage?.endDate)
        : "",
      search: brandCreateState?.detailsPage?.search,
    };
    brandsInnerTableExport(data).then((response) => {
      if (response?.data?.code === 200) {
        toast.success(
          response?.data?.message ?? "Table has send to registered email"
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return {
    isLoading,
    tableFields,
    headerDetails,
    tableFetching,
    showEditModal,
    initialDate,
    reportFetching,
    brandCreateState,
    paginationOptions,
    brandDetailsTableList,
    getRow,
    handleSort,
    refetch,
    handleSearch,
    handlePageSize,
    handleExportTable,
    handlePagination,
    handleDateChange,
    handleClearClick,
    handleEditColumnsClick,
    updateBrandDetailsTableFields,
  };
};

export default useBrandsDetail;
