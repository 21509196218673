import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  endDate:"",
  startDate:"",
  sortBy: "",
  sortOrder: "",
  search: "",
  activeTab: "pending",
  viewLinkModal: false,
  linkData: [],
  isPending: false,
  createFilterModal:false,
};

const walletTransactionSlice = createSlice({
  name: "walletTransaction",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = walletTransactionSlice.actions;
export default walletTransactionSlice.reducer;
