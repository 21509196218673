import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useViewLinks from "./useViewLinks";
import EmptyData from "../../../Global/EmptyData";
import Style from "../wallet.module.scss";
const ViewLinks = ({ data }) => {
  const { toPascalCase, getSocialMediaName } = useViewLinks();
  return (
    <div className="col">
      {data?.length !== 0 && data ? (
        data?.map((value, index) => (
          <div
            className="pro-mb-4 pro-border-bottom pro-pb-4"
            key={`outer-${index}`}
          >
            <label
              htmlFor="servicetype"
              className="pro-font-regular pro-fw-bolder pro-mb-2"
            >
              {toPascalCase(value?.stream)}
            </label>
            <div>
              {value?.attachment?.map((singleData, i) =>
                singleData?.url_or_file === 1 ? (
                  <div
                    className="pro-d-flex pro-gap-2 pro-pnt pro-mb-2 pro-items-center text-truncate"
                    key={i}
                    onClick={() => window.open(singleData?.url, "_blank")}
                  >
                    <Image
                      src={getSocialMediaName(singleData?.content_type)}
                      alt="social media icon"
                      width={16}
                      height={16}
                      propStyle={{
                        root: Style.image,
                      }}
                    />
                    <p
                      className="pro-mb-0 pro-pnt pro-fw-medium text-truncate"
                      key={i}
                    >
                      {singleData?.url.length > 40
                        ? `${singleData?.url.slice(0, 40)}...`
                        : singleData?.url}
                    </p>
                  </div>
                ) : (
                  <Image
                    src={singleData?.file}
                    alt="file icon"
                    width={100}
                    height={100}
                    key={i}
                  />
                )
              )}
            </div>
          </div>
        ))
      ) : (
        <EmptyData />
      )}
    </div>
  );
};

export default ViewLinks;
