import { getAxiosInstance } from "../../../API";

export const addUser = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/users/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getUserDetails = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/users/${id}/view`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateUser = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/users/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const exportUserTable = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/users/export`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
