import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePaymentSection from "./usePaymentSection";
// import Assets from "../../../../../Assets";
import Style from "../verify.module.scss";

const PaymentSection = ({ listRefetch, linkTabData }) => {
  const {
    // amount,
    errors,
    paymentDetails,
    handleAmountChange,
    handleModalClose,
    handleSubmit,
    amount
  } = usePaymentSection({ listRefetch, linkTabData });
  
  return (
    <div className="pro-ps-4">
      <h4 className="pro-ttl pro-mb-4">Payment</h4>
      <div
        className={`pro-d-flex pro-items-center pro-gap-2 pro-pb-4               pro-mb-4 ${Style.amount_desc_wrap}`}
      >
        <div
          className={`pro-d-flex pro-items-center pro-gap-2 ${Style.amount_wrap}`}
        >
          <div className={`${Style.icon_wallet}`}>
            <svg
              width="33"
              height="25"
              viewBox="0 0 33 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 3.125C0 1.40625 1.485 0 3.3 0H28.05C28.4876 0 28.9073 0.16462 29.2167 0.457646C29.5262 0.750671 29.7 1.1481 29.7 1.5625V3.125H3.3V4.6875H31.35C31.7876 4.6875 32.2073 4.85212 32.5167 5.14515C32.8262 5.43817 33 5.8356 33 6.25V21.875C33 22.7038 32.6523 23.4987 32.0335 24.0847C31.4146 24.6708 30.5752 25 29.7 25H3.3C2.42479 25 1.58542 24.6708 0.966548 24.0847C0.347678 23.4987 0 22.7038 0 21.875V3.125ZM27.225 17.1875C27.8814 17.1875 28.5109 16.9406 28.9751 16.501C29.4392 16.0615 29.7 15.4654 29.7 14.8438C29.7 14.2221 29.4392 13.626 28.9751 13.1865C28.5109 12.7469 27.8814 12.5 27.225 12.5C26.5686 12.5 25.9391 12.7469 25.4749 13.1865C25.0108 13.626 24.75 14.2221 24.75 14.8438C24.75 15.4654 25.0108 16.0615 25.4749 16.501C25.9391 16.9406 26.5686 17.1875 27.225 17.1875Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={`pro-ms-1 ${Style.amount}`}>
            {" "}
            <strong className="pro-d-block">Campaign Balance</strong>
            <span className="pro-d-block">
              {paymentDetails?.data?.campaign_balance_amount}
            </span>
          </div>
        </div>
        <p className={`pro-mb-0 pro-ms-1 ${Style.desc}`}>
          {paymentDetails?.data?.payment_description ?? ""}
        </p>
      </div>
      <div className="social_l=listing">
        <div className="social_items">
          <h5 className="pro-mb-4">Payment Details</h5>
          {paymentDetails?.data?.social_media?.map((val, index) => {
            return (
              <div
                key={index}
                className={`pro-d-flex pro-justify-between ${Style.social_amount}`}
              >
                <p className="pro-mb-3">
                  {val?.social_media?.[0]?.toUpperCase() +
                    val?.social_media?.slice(1)}
                </p>
                <p className="pro-mb-3">{val?.allowed_amount}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`pro-d-flex pro-mb-2 pro-justify-between ${Style.total_amount}`}
      >
        <p>
          <strong>Total</strong>
        </p>
        <p>
          <strong>{paymentDetails?.data?.total_allowed_amount}</strong>
        </p>
      </div>
      <div className={Style.amount_input_wrap}>
        <Input
          label={"Enter Amount to be Added in Wallet"}
          error={errors}
          errorMessage={errors}
          onChange={(e) => handleAmountChange(e)}
          disabled={linkTabData?.data[1]?.status !== "completed" ? false : true}
          value={amount}
        />
      </div>
      {/* {errors && <span>{errors}</span>} */}

      {linkTabData?.data[1]?.status !== "completed" && (
        <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleModalClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleSubmit}
          type="button"
        >
          Submit
        </Button>
      </div>
      )}
      
    </div>
  );
};

export default PaymentSection;
