import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProfileData, updateConfig } from "../../../../Store/Slices/Global";
import { useGetNotificationsListDataQuery } from "../../../../Store/Queries/Notifications";
import { MarkNotificationRead } from "./api";
import { useEffect } from "react";
import { updateConfig as notificationUpdateConfig } from "../../../../Store/Slices/Notifications/notificationSlice";
import { updateConfig as updateSupportConfig } from "../../../../Store/Slices/Support/suppportSlice";
import { toast } from "react-toastify";
import Style from "./HeaderActions.module.scss"

const useHeaderActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.global);
  const notificationsState = useSelector((state) => state.notifications);

  const {
    data: notifications = {},
    isFetching: notificationsFetching,
    refetch,
  } = useGetNotificationsListDataQuery({
    items_per_page: notificationsState.currentPageSize,
    current_page: notificationsState.currentPage,
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
      if(notifications?.data?.unread_count>0){
      toast.dismiss();
      toast(
        notifications?.data?.unread_count === 1 
          ? `You have 1 unread message.` 
          : notifications?.data?.unread_count === 0 
            ? `You have 0 unread messages.` 
            : `You have ${notifications?.data?.unread_count ?? 0} unread messages.`,
        {
          icon: <span className="material-symbols-outlined x4">mark_chat_unread</span>,
          className: Style.customToast
        }
      );        
    }
    }, 30000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [notifications]);

  useEffect(() => {
    let notificationData = notifications?.data?.data?.filter(
      (item) => item.read_at === null
    );
    if (notificationData?.length > 0) {
      dispatch(
        notificationUpdateConfig((state) => {
          state.isNewNotification = true;
        })
      );
    } else {
      dispatch(
        notificationUpdateConfig((state) => {
          state.isNewNotification = false;
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications?.data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsState.isNewNotification]);

  const handleLogout = () => {
    localStorage.removeItem("USER_ACCESS_TOKEN_INVESTO");
    localStorage.removeItem("menu_permissions");
    navigate("/");
  };

  const handleSettings = () => {
    navigate("/Settings");
  };

  const handleViewProfile = () => {
    dispatch(updateConfig((state) => (state.profileModal = true)));
    dispatch(getProfileData());
  };

  const handleCloseProfile = () => {
    dispatch(updateConfig((state) => (state.profileModal = false)));
  };

  const handleNotificationsRedirect = () => {
    navigate("/notifications");
  };
  const handleNotificationRedirect = async (item) => {
    const url = item?.redirection_url;
    const id = item?._id;
    if (item?.redirection_url.includes("/CMS/support")) {
      dispatch(
        updateSupportConfig((state) => {
          state.chatId = item?.redirection_id;
          state.chatModal = true;
        })
      );
    }
    let data = {
      notification_id: id,
    };
    MarkNotificationRead(data).then(() => {});
    navigate(`${url}`);
  };
  return {
    globalState,
    notifications,
    isNewNotification: notificationsState.isNewNotification,
    notificationsFetching,
    handleLogout,
    handleSettings,
    handleViewProfile,
    handleNotificationRedirect,
    handleCloseProfile,
    handleNotificationsRedirect,
  };
};

export default useHeaderActions;
