import React from "react";
import DashboardCardShimmer from "../../Global/Shimmers/DashboardCardShimmer";
import DashboardCard from "../DashboardCard";
import Style from "./dashboardHero.module.scss";

const DashboardHero = ({
  data,
  obj,
  transactions = false,
  loading,
  listingType,
  shimmerCount,
  from=false
}) => {
  return (
    <div className={`pro-w-100`}>
      <div className={`row g-${from?4:5} row-cols-${from?4:5} row-cols-lg-${from?4:5}`}>
        {!loading ? (
          <>
            {Object.keys(data || {})?.map((key) => {
              return (
                <React.Fragment key={key}>
                  <div
                    className={`col-4 ${obj?.[key]?.handleClick ? Style.dashboardCard_root:""}`}
                    onClick={() => {
                      if(obj?.[key]?.handleClick){
                        obj?.[key]?.handleClick(obj?.[key]?.label)
                      }else{
                        return
                      }}}
                  >
                    <DashboardCard
                      icon={obj?.[key]?.icon}
                      title={obj?.[key]?.title}
                      data={data?.[key]}
                      prev={obj?.[key]?.prev}
                      active={obj?.[key]?.label === listingType ? true : false}
                      transactions={transactions}
                      reactIcon={obj?.[key]?.reactIcon}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          [...Array(shimmerCount)].map((_, i) => (
            <div className="col-4" key={i}>
              <DashboardCardShimmer key={i} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardHero;
