import React from "react";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";
import {
  Header,
  Image,
  MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import HeaderActions from "../../../Pages/Layouts/CommonLayout/HeaderActions";
import CampaignLayoutSidebarShimmer from "../../Global/Shimmers/CampaignLayoutSidebarShimmer";
import useCommonLayout from "../../../Pages/Layouts/CommonLayout/useCommonLayout";
import useBrandsDetailsLayout from "./useBrandsDetailsLayout";
import Assets from "../../../Assets";
import Style from "../../../Pages/Layouts/CommonLayout/commonLayout.module.scss";
import brandStyle from "./brandsdetailslayout.module.scss";

const BrandsDetailslayout = () => {
  const {
    isFetching,
    brandData,
    activeUser,
    nameWithAge,
    basicDetails,
    handleBackClick,
  } = useBrandsDetailsLayout();

  const { navigations, globalState } = useCommonLayout();
  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${brandStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${brandStyle.left_sidebar}`}>
            {isFetching && Object.keys(brandData?.data ?? {})?.length === 0 ? (
              <>
                <CampaignLayoutSidebarShimmer />
              </>
            ) : (
              <>
                <ProfileSideBar extraClassName={brandStyle.left_sidebar_sticky}>
                  <div className={`pro-px-5 pro-pt-5`}>
                    <div
                      className={`pro-py-3 pro-px-2 pro-border-bottom pro-mb-4`}
                    >
                      <button
                        className="pro-back-btn pro-items-center pro-d-flex"
                        onClick={handleBackClick}
                      >
                        <span className="icon">
                          <span className="material-symbols-outlined x4">
                            chevron_left
                          </span>
                        </span>
                        <span className="title">
                          <b>Back to Brands</b>
                        </span>
                      </button>
                    </div>
                    <div className={`${brandStyle.left_sidebar_sticky_top}`}>
                      <div className={brandStyle.profile}>
                        <Image
                          src={`${
                            brandData?.data?.logo ?? Assets.PROFILE_USER_SQURE
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>

                      <SimpleTextDetails
                        title={`${nameWithAge ?? ""}`}
                        extraClassNames={`pro-mt-4 pro-mb-2 pro-ms-2 ${brandStyle.profile_name_wrap}`}
                      />
                    </div>
                    <div>
                      <span className="pro-badge badge-primary-light">
                        {brandData?.data?.category}
                      </span>
                    </div>
                    <div
                      className={`${brandStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <MultiColumnTable
                        title={"Basic Details"}
                        data={basicDetails}
                        extraClassNames={`pro-mb-5`}
                      />
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          {/* main container */}
          <div className={`${brandStyle.main_container}`}>
            <div className={`${brandStyle.main_container_inner}`}>
              <Outlet context={{ activeUser }} />
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default BrandsDetailslayout;
