import { getAxiosInstance } from "../../../../API";

export const addBrand = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/brand/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateBrand = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/brand/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getBrandData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/brand/${params}/view`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
