import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Policy/policySlice";
import { useSelector } from "react-redux";
import { useGetPolicyDataQuery } from "../../../Store/Queries/Policy";
import { useEffect } from "react";

const usePolicy = () => {
  const { editModal } = useSelector((state) => state.policy);
  const { data: policy = {}, refetch } = useGetPolicyDataQuery({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.description = policy?.data?.data?.description;
        state.ar_description = policy?.data?.data?.ar_description;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy?.data?.data?.description, policy?.data?.data?.ar_description]);

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.editModal = false)));
  };

  const handleOpenModal = () => {
    dispatch(updateConfig((state) => (state.editModal = true)));
  };

  return {
    policy,
    editModal,
    refetch,
    handleCloseModal,
    handleOpenModal,
  };
};

export default usePolicy;
