import { useEffect, useState } from "react";
import { useGetCampaignDashboardDataQuery } from "../../../../Store/Queries/Campaign";

const useCardHero = () => {
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetCampaignDashboardDataQuery({});

  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {}) ?? "");
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  let dashboardItems = {
    total_campaigns: {
      label: "total_campaigns",
      title: "Total Campaigns",
      icon: "campaign",
      handleClick: () => {},
    },
    approved_request: {
      label: "approved_request",
      title: "Approved",
      icon: "task_alt",
      handleClick: () => {},
    },
    pending_request: {
      label: "pending_request",
      title: "Pending",
      icon: "pending_actions",
      handleClick: () => {},
    },
    rejected_request: {
      label: "rejected_request",
      title: "Rejected",
      icon: "block",
      handleClick: () => {},
    },
  };
  return {
    dashboardData,
    listingType,
    dashboardItems,
    isFetching,
    isSuccess,
  };
};

export default useCardHero;
