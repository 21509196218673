import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../API";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("type")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("type")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const policy = createApi({
  reducerPath: "policyApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["policy"],
  endpoints: (builder) => ({
    getPolicyData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/policy/view`,
      }),
      providesTags: ["policy"],
    }),
  }),
});

export const { useGetPolicyDataQuery } = policy;
