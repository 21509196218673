
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Accordion from "react-bootstrap/Accordion";
// import Assets from "../../../Assets";
// import Style from "./social_accordian.module.scss";

const Accordian = ({ task , caption , hashtag , brand_mention, dos , donots }) => {
    return (
        <div>
            <div className={`social-accdn-wrap `}>
                <Accordion defaultActiveKey="" flush>
                    <Accordion.Item
                        eventKey={1}
                        className={`tasks pro-mb-3`}
                        key={1}
                    >
                        <Accordion.Header className="pro-m-0">
                            <div className="pro-d-flex pro-justify-between pro-w-100">
                                <div className="amount_wrap pro-d-flex pro-items-center">
                                    <strong>
                                        Task Details
                                    </strong>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="categories_types pro-mb-4">
                                {task?.length > 0 && (
                                    <>
                                        <span className="title">
                                            <strong>Tasks</strong>
                                        </span>

                                        <div className="pro-d-flex pro-flex-wrap pro-gap-2 tags_wrap">
                                            {task?.map(
                                                (inf, infIndex) => {
                                                    return (
                                                        <p
                                                            key={infIndex}
                                                        //   className="pro-bg-white pro-px-4 pro-py-1 pro-rounded-pill"
                                                        >
                                                            {inf}
                                                        </p>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="categories_types pro-mb-4">
                                {caption && (
                                    <>
                                        <span className="title">
                                            <strong>Caption Ideas</strong>
                                        </span>

                                        <div className="pro-d-flex pro-flex-wrap pro-gap-2 tags_wrap">
                                            <p
                                            //   className="pro-bg-white pro-px-4 pro-py-1 pro-rounded-pill"
                                            >
                                                {caption}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="categories_types pro-mb-4">
                                {hashtag && (
                                    <>
                                        <span className="title">
                                            <strong>Hash Tag</strong>
                                        </span>

                                        <div className="pro-d-flex pro-flex-wrap pro-gap-2 tags_wrap">
                                            <p
                                            //   className="pro-bg-white pro-px-4 pro-py-1 pro-rounded-pill"
                                            >
                                                {hashtag}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="categories_types pro-mb-4">
                                {brand_mention && (
                                    <>
                                        <span className="title">
                                            <strong>Brand Mentions</strong>
                                        </span>

                                        <div className="pro-d-flex pro-flex-wrap pro-gap-2 tags_wrap">
                                            <p
                                            //   className="pro-bg-white pro-px-4 pro-py-1 pro-rounded-pill"
                                            >
                                                {brand_mention}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="categories_types pro-mb-4">
                                {dos?.length > 0 && (
                                    <>
                                        <span className="title">
                                            <strong>Dos</strong>
                                        </span>

                                        <div className=" pro-gap-2 tags_wrap">
                                            {dos?.map(
                                                (inf, infIndex) => {
                                                    return (
                                                        <p className="pro-mb-2"
                                                            key={infIndex}
                                                        //   className="pro-bg-white pro-px-4 pro-py-1 pro-rounded-pill"
                                                        >
                                                            {inf}
                                                        </p>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="categories_types pro-mb-4">
                                {donots?.length > 0 && (
                                    <>
                                        <span className="title">
                                            <strong>Don'ts</strong>
                                        </span>

                                        <div className=" pro-gap-2 tags_wrap">
                                            {donots?.map(
                                                (inf, infIndex) => {
                                                    return (
                                                        <p className="pro-mb-2"
                                                            key={infIndex}
                                                        //   className="pro-bg-white pro-px-4 pro-py-1 pro-rounded-pill"
                                                        >
                                                            {inf}
                                                        </p>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
};

export default Accordian;
