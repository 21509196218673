import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Report/reportSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetReportDataQuery,useUpdateReportTableHeadDataMutation } from "../../../Store/Queries/Campaign";
import Style from "./report.module.scss"
import Assets from "../../../Assets";
import { IconText, Image } from "@wac-ui-dashboard/wac_component_library";
import { getFormatedDate } from "../../../utils/Table";
import SocialIcons from "../../Global/SocialIcons";
import { toast } from "react-toastify";
import { ExportReportTable } from "./api";

const useReport = () => {
  const dispatch = useDispatch();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const reportState = useSelector(
    (state) => state.report
  );
  const navigate = useNavigate();
  const [updateFields] = useUpdateReportTableHeadDataMutation();
  const [initialDate, setInitialDate] = useState({
    // startDate: subDays(new Date(), 30),
    // endDate: subDays(new Date(), -30),
    startDate: reportState.startDate
      ? reportState.startDate
      : null,
    endDate: reportState.endDate
      ? reportState.endDate
      : null,
    key: "selection",
  });
  const {
    data: reportList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetReportDataQuery({
    items_per_page: reportState.currentPageSize,
    page: reportState.currentPage,
    sort_by: reportState.sortBy,
    sort_order: reportState.sortOrder,
    search: reportState.search,
    start_date: reportState.startDate
      ? getFormatedDate(reportState.startDate)
      : "",
    end_date: reportState.endDate
      ? getFormatedDate(reportState.startDate)
      : "",
    status: reportState.status?.id,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = reportList?.data?.fields;
        state.currentPage = 1;
      })
    );
    // eslint-disable-next-line
  }, [reportList]);
  const handleClickUser=(data)=>{
    if(reportList?.data?.permissions?.can_view_user){
      navigate({ pathname: `/user-details/${data?.user_id}` })
    }else{
      return
    }
  }
  const handleClickBrand=(data)=>{
    if(reportList?.data?.permissions?.can_view_brand){
      navigate({ pathname: `/brand-details/${data?.brand_id}` })
    }else{
      return
    }
  }
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      user: (feild, data) => (
        <div className={`${reportList?.data?.permissions?.can_view_user? "pro-pnt":""} text-truncate`}>
          <IconText
            icon={
              <img
                src={data["user_thumb_image"] ?? Assets.PROFILE_USER}
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            onClick={() => handleClickUser(data)}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      campaign_name: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            reportList?.data?.permissions?.can_view_campaign
              ? `pro-pnt text-truncate `
              : ""
          }`}
          onClick={() => {
            if (reportList?.data?.permissions?.can_view_campaign) {
              navigate({
                pathname: `/campaign-details/${data?.campaign_id}activeTab="all"&type="report"`,
              });
              sessionStorage.setItem("active-campaign", `${data?.campaign_id}`);
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
      brand: (feild, data) => (
        <div className={`${Style.brand} ${reportList?.data?.permissions?.can_view_brand?"pro-pnt":""}`}  onClick={() => handleClickBrand(data)} >
          <div className="pro-avatar">
            <Image
              src={data["brand_logo_thumbnail"] ?? Assets?.BRAND_ICON2}
              alt="brand-logo"
              width={22}
              height={22}
            />
          </div>
          <p className="pro-mb-0 pro-ms-2 no-wrap">{data[feild]}</p>
        </div>
      ),
      stream: (feild, data) => <SocialIcons icons={data[feild]} />,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
          reportState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.startDate = "";
        state.endDate = "";
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.status = "";
      })
    );
    setInitialDate({
      startDate: null,
      endDate: null,
      key: "selection",
    });
  };
  const handleExportTable = () => {
    let data = {
      search: reportState.search,
      start_date: reportState.startDate
        ? getFormatedDate(reportState.startDate)
        : "",
      end_date: reportState.endDate
        ? getFormatedDate(reportState.startDate)
        : "",
      status: reportState.status?.id,
    };
    ExportReportTable(data).then((response) => {
      if (response?.data?.code === 200) {
        toast.success(
          response?.data?.message ?? "Table has send to registered email"
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.startDate = new Date(date.startDate);
        state.endDate = new Date(date.endDate);
      })
    );
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  return {
    getRow,
    refetch,
    handleSort,
    updateFields,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick,
    handleEditColumnsClick,
    handleDateChange,
    handleExportTable,
    initialDate,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    reportList,
    reportState,
    handleCloseFilter,
    handleFilterClick,
  };
};

export default useReport;
