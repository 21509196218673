import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Assets from "../../../Assets";

const SocialIcons = ({ icons = [], hasToolTip = false }) => {
  return (
    <>
      {hasToolTip ? (
        <div className={"socia-icons"}>
          {/* eslint-disable-next-line array-callback-return */}
          {icons?.map((value, index) => {
            switch (value?.platform) {
              case "facebook":
                return (
                  <div
                    className="icon-wrap"
                    key={index}
                    title={
                      value?.media_type?.length > 0
                        ? value?.media_type?.map((v) => v)
                        : "facebook"
                    }
                  >
                    <Image
                      src={Assets.FACEBOOK_ICON}
                      alt={"facebook"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "instagram":
                return (
                  <div
                    className="icon-wrap"
                    key={index}
                    title={
                      value?.media_type?.length > 0
                        ? value?.media_type?.map((v) => v)
                        : "instagram"
                    }
                  >
                    <Image
                      src={Assets.INSTAGRAM_ICON}
                      alt={"instagram"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "youtube":
                return (
                  <div
                    className="icon-wrap"
                    key={index}
                    title={
                      value?.media_type?.length > 0
                        ? value?.media_type?.map((v) => v)
                        : "youtube"
                    }
                  >
                    <Image
                      src={Assets.YOUTUBE_ICON}
                      alt={"youtube"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "tiktok":
                return (
                  <div
                    className="icon-wrap"
                    key={index}
                    title={
                      value?.media_type?.length > 0
                        ? value?.media_type?.map((v) => v)
                        : "tiktok"
                    }
                  >
                    <Image
                      src={Assets.TIKTOK_ICON}
                      alt={"tiktok"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "linkedin":
                return (
                  <div
                    className="icon-wrap"
                    key={index}
                    title={
                      value?.media_type?.length > 0
                        ? value?.media_type?.map((v) => v)
                        : "linkedin"
                    }
                  >
                    <Image
                      src={Assets.LINKEDIN_ICON}
                      alt={"linkedin"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "google":
                return (
                  <div
                    className="icon-wrap"
                    key={index}
                    title={
                      value?.media_type?.length > 0
                        ? value?.media_type?.map((v) => v)
                        : "google"
                    }
                  >
                    <Image
                      src={Assets.GOOGLE_ICON}
                      alt={"google"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              default:
                break;
            }
          })}
        </div>
      ) : (
        <div className={"socia-icons"}>
          {/* eslint-disable-next-line array-callback-return */}
          {icons?.map((value, index) => {
            switch (value) {
              case "facebook":
                return (
                  <div className="icon-wrap" key={index} title="ggggg">
                    <Image
                      src={Assets.FACEBOOK_ICON}
                      alt={"facebook"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "instagram":
                return (
                  <div className="icon-wrap" key={index}>
                    <Image
                      src={Assets.INSTAGRAM_ICON}
                      alt={"instagram"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "youtube":
                return (
                  <div className="icon-wrap" key={index}>
                    <Image
                      src={Assets.YOUTUBE_ICON}
                      alt={"youtube"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "tiktok":
                return (
                  <div className="icon-wrap" key={index}>
                    <Image
                      src={Assets.TIKTOK_ICON}
                      alt={"tiktok"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "linkedin":
                return (
                  <div className="icon-wrap" key={index}>
                    <Image
                      src={Assets.LINKEDIN_ICON}
                      alt={"linkedin"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              case "google":
                return (
                  <div className="icon-wrap" key={index}>
                    <Image
                      src={Assets.GOOGLE_ICON}
                      alt={"google"}
                      width={16}
                      height={16}
                    />
                  </div>
                );
              default:
                break;
            }
          })}
        </div>
      )}
    </>
  );
};

export default SocialIcons;
