import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../API";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  addModal: false,
  allRoles: [],
  allRolesStatus: "idle",
  allPermissions: [],
  allPermissionsStatus: "idle",
  rolePermission: {},
  rolePermissionStatus: "idle",
};

export const getAllRoles = createAsyncThunk(
  "roles/getAllRoles",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/roles`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllPermissions = createAsyncThunk(
  "roles/getAllPermissions",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/permissions`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPermission = createAsyncThunk(
  "roles/getPermission",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/roles/${params.id}/view`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoles.pending, (state) => {
        state.allRolesStatus = "pending";
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.allRolesStatus = "success";
        state.allRoles = action.payload?.data?.data;
      })
      .addCase(getAllRoles.rejected, (state) => {
        state.allRolesStatus = "failed";
      })
      .addCase(getAllPermissions.pending, (state) => {
        state.allPermissionsStatus = "pending";
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.allPermissionsStatus = "success";
        state.allPermissions = action.payload?.data?.data;
      })
      .addCase(getAllPermissions.rejected, (state) => {
        state.allPermissionsStatus = "failed";
      })
      .addCase(getPermission.pending, (state) => {
        state.rolePermissionStatus = "pending";
      })
      .addCase(getPermission.fulfilled, (state, action) => {
        state.rolePermission[action.meta.arg.id] = action.payload?.data?.data;
        state.rolePermissionStatus = "fulfilled";
      })
      .addCase(getPermission.rejected, (state) => {
        state.rolePermissionStatus = "rejected";
      });
  },
});

export const { updateConfig } = rolesSlice.actions;
export default rolesSlice.reducer;
