/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Accordion from "react-bootstrap/Accordion";
// import Assets from "../../../Assets";
import Style from "./social_accordian.module.scss";
import SocialIconSingle from "../SocialIconSingle";

const SocialAccordian = ({ socialItems = {}, isPost = false }) => {
  return (
    <div>
      <div className={`social-accdn-wrap ${Style.accdn_wrap}`}>
        <Accordion defaultActiveKey="" flush>
          {Object.keys(socialItems).map((key, index) => {
            return (
              <Accordion.Item
                eventKey={index}
                className={`${key} pro-mb-3`}
                key={index}
              >
                <Accordion.Header className="pro-m-0">
                  <div className="pro-d-flex pro-justify-between pro-w-100">
                    <div className="social_wrap pro-d-flex pro-items-center ">
                      <i className="pro-me-1">
                        <SocialIconSingle icon={key} />
                      </i>
                      {key?.[0]?.toUpperCase() + key?.slice(1)}
                    </div>
                    <div className="amount_wrap pro-d-flex pro-items-center">
                      <strong>
                        {socialItems?.[key]?.unit_rate}{" "}
                        {socialItems?.[key]?.unit_rate && "AED"}{" "}
                      </strong>
                      / <span> {isPost ? "Post" : "Engagement"}</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="categories_types pro-mb-4">
                    {socialItems?.[key]?.influencer_category?.length > 0 && (
                      <>
                        <span className="title">
                          <strong>Influencer Category</strong>
                        </span>

                        <div className="pro-d-flex pro-flex-wrap pro-gap-2 tags_wrap">
                          {socialItems?.[key]?.influencer_category?.map(
                            (inf, infIndex) => {
                              return (
                                <a
                                  key={infIndex}
                                  className="pro-bg-white pro-px-4 pro-py-1 pro-rounded-pill"
                                >
                                  {inf}
                                </a>
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="categories_types pro-mb-4">
                    {socialItems?.[key]?.media_type?.length > 0 && (
                      <div className="title">
                        <strong>Media Types</strong>
                      </div>
                    )}
                    <div className="pro-d-flex pro-flex-wrap pro-gap-2 tags_wrap">
                      {socialItems?.[key]?.media_type?.map(
                        (type, typeIndex) => {
                          return (
                            <a
                              key={typeIndex}
                              className="pro-bg-white pro-px-4 pro-py-1 pro-rounded-pill"
                            >
                              {type}
                            </a>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default SocialAccordian;
