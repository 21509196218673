import React from "react";
import Style from "../../contentVerification.module.scss"

const Lables = ({ value }) => {
  return (
    <div className={Style.approve_reject_wrap}>
      {value?.uploaded_date && <p><strong> Uploaded on :</strong> <span>{value?.uploaded_date}</span></p>}
      {value?.approved_by && <p><strong>Approved by :</strong> <span>{value?.approved_by}</span></p>}
      {value?.approved_date && <p><strong>Approved on : </strong><span>{value?.approved_date}</span></p>}
      {value?.rejected_by && <p><strong>Rejected by :</strong> <span>{value?.rejected_by}</span></p>}
      {value?.rejected_date && <p><strong>Rejected date :</strong> <span>{value?.rejected_date}</span></p>}
      {value?.rejected_reason && (
        <p><strong>Reject reason :</strong> <span>{value?.rejected_reason}</span> </p>
      )}
    </div>
  );
};

export default Lables;
