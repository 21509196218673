import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/User/userSlice";
import * as Yup from "yup";

const useFliterUser = ({ refetch,activeTab}) => {
  const dispatch = useDispatch();
  const { basicData } = useSelector((state) => state.global);
  const usersState = useSelector((state) => state.users);
  const validationSchema = Yup.object({
    social_media_type: Yup.lazy(() =>
      activeTab === "all"
        ? Yup.object().required("Enter social media")
        : Yup.object()
    ),  
    social_media_status: Yup.lazy(() =>
      activeTab === "all"
        ? Yup.object().required("Enter social media status")
        : Yup.object()
    ),
    category: Yup.object(),
  });
  
  const formik = useFormik({
    initialValues: {
      social_media_type: usersState?.social_media_type,
      social_media_status: usersState?.social_media_status,
      category: usersState?.category,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateConfig((state) => {
          state.social_media_status = activeTab === "all" ? (values?.social_media_status ? values?.social_media_status:""):"";
          state.social_media_type = activeTab === "all"?(values?.social_media_type ? values?.social_media_type:""):"";
          state.category =values?.category ? values?.category:"";
        })
      );
      handleCloseModal();
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };

  return {
    formik,
    getFieldError,
    handleCloseModal,
    basicData,
  };
};

export default useFliterUser;
