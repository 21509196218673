import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Assets from "../../../Assets";
import Style from "./socialIcon.module.scss";

const SocialIcon = ({ icons = [] }) => {
  return (
    <div className={"socia-icons"}>
      {/* eslint-disable-next-line array-callback-return */}
      {icons?.map((value, index) => {
        switch (value?.stream ?? value) {
          case "facebook":
            return (
              <div className="pro-d-flex pro-items-center text-truncate" key={index}>
                <Image
                  src={Assets.FACEBOOK_ICON}
                  alt={"facebook"}
                  width={16}
                  height={16}
                  propStyle={{
                    root: Style.image,
                  }}
                />
                {value?.influencer_category && (
                  <p className={`pro-mb-0 flex-fill ${Style.name}`}>
                    {value?.influencer_category?.charAt(0)?.toUpperCase() +
                      value?.influencer_category?.slice(1)}
                  </p>
                )}
              </div>
            );
          case "instagram":
            return (
              <div className="pro-d-flex pro-items-center text-truncate" key={index}>
                <Image
                  src={Assets.INSTAGRAM_ICON}
                  alt={"instagram"}
                  width={16}
                  height={16}
                  propStyle={{
                    root: Style.image,
                  }}
                />
                {value?.influencer_category && (
                  <p className={`pro-mb-0 flex-fill ${Style.name}`}>
                    {value?.influencer_category?.charAt(0)?.toUpperCase() +
                      value?.influencer_category?.slice(1)}
                  </p>
                )}
              </div>
            );
          case "youtube":
            return (
              <div className="pro-d-flex pro-items-center text-truncate" key={index}>
                <Image
                  src={Assets.YOUTUBE_ICON}
                  alt={"youtube"}
                  width={16}
                  height={16}
                  propStyle={{
                    root: Style.image,
                  }}
                />
                {value?.influencer_category && (
                  <p className={`pro-mb-0 flex-fill ${Style.name}`}>
                    {value?.influencer_category?.charAt(0)?.toUpperCase() +
                      value?.influencer_category?.slice(1)}
                  </p>
                )}
              </div>
            );
          case "tiktok":
            return (
              <div className="pro-d-flex pro-items-center text-truncate" key={index}>
                <Image
                  src={Assets.TIKTOK_ICON}
                  alt={"tiktok"}
                  width={16}
                  height={16}
                  propStyle={{
                    root: Style.image,
                  }}
                />
                {value?.influencer_category && (
                  <p className={`pro-mb-0 flex-fill ${Style.name}`}>
                    {value?.influencer_category?.charAt(0)?.toUpperCase() +
                      value?.influencer_category?.slice(1)}
                  </p>
                )}
              </div>
            );
          case "linkedin":
            return (
              <div
                className="icon-wrap pro-d-flex pro-items-center text-truncate"
                key={index}
              >
                <Image
                  src={Assets.LINKEDIN_ICON}
                  alt={"linkedin"}
                  width={16}
                  height={16}
                  propStyle={{
                    root: Style.image,
                  }}
                />
                {value?.influencer_category && (
                  <p className={`pro-mb-0 flex-fill ${Style.name}`}>
                    {value?.influencer_category?.charAt(0)?.toUpperCase() +
                      value?.influencer_category?.slice(1)}
                  </p>
                )}
              </div>
            );
          case "google":
            return (
              <div
                className="icon-wrap pro-d-flex pro-items-center text-truncate"
                key={index}
              >
                <Image
                  src={Assets.GOOGLE_ICON}
                  alt={"google"}
                  width={16}
                  height={16}
                  propStyle={{
                    root: Style.image,
                  }}
                />
                {value?.influencer_category && (
                  <p className={`pro-mb-0 flex-fill ${Style.name}`}>
                    {value?.influencer_category?.charAt(0)?.toUpperCase() +
                      value?.influencer_category?.slice(1)}
                  </p>
                )}
              </div>
            );
          default:
            break;
        }
      })}
    </div>
  );
};

export default SocialIcon;
