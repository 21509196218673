import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Campaign/Creation/CreationSlice";
import { useSelector } from "react-redux";

const useDos = ({ formik, handleMoveToErrorTab }) => {
  const { isEdit, isLiveEdit } = useSelector((state) => state.campaignCreate);
  const dispatch = useDispatch();
  const handleAddDos = () => {
    let { dos } = formik?.values;
    dos = [
      ...dos,
      {
        do: "",
        ar_do: "",
      },
    ];
    formik.setFieldValue("dos", dos);
  };

  const handleAddDons = () => {
    let { dons } = formik?.values;
    dons = [
      ...dons,
      {
        don: "",
        ar_don: "",
      },
    ];
    formik.setFieldValue("dons", dons);
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };

  const handleSubmit = () => {
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    );
    formik?.values?.platform.forEach((_, index) => {
      formik.setFieldTouched(`platform[${index}].p_name`, true);
      formik.setFieldTouched(`platform[${index}].p_type`, true);
      formik.setFieldTouched(`platform[${index}].m_type`, true);
    });
    if (
      formik?.values?.platform?.some(
        (item) => item?.p_name?.name?.toLowerCase() === "google"
      )
    ) {
      formik?.setFieldTouched("maps");
    }
    handleMoveToErrorTab();
  };

  return {
    isEdit,
    isLiveEdit,
    handleAddDos,
    handleAddDons,
    handleCloseModal,
    handleSubmit,
  };
};

export default useDos;
