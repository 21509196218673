import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  notificationData: {},
  notificationStatus: "idle",
  isNewNotification: false,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "",
  search: "",
  startDate: null,
  endDate: null,
  notification_type:"",
  deleteModal:false,
  notificationModal: false
};
const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  }
});
export const { updateConfig } = notificationsSlice.actions;

export default notificationsSlice.reducer;
