import React from "react";
// import verifyStyle from "./verify.module.scss";
import useVerify from "./useVerify";
import LinkSection from "./LinkSection";
import PaymentSection from "./PaymentSection";

const Verify = ({ listRefetch }) => {
  const { divElements, verifyTab, linkTabData, refetch } = useVerify();

  return (
    <div className="row pro-h-100">
      <div className="col-4 pro-border-right">{divElements}</div>
      <div className="col-8 pro-ps-0">
        {verifyTab === "Verify Links" ? (
          <LinkSection
            linkTabData={linkTabData}
            refetch={refetch}
            listRefetch={listRefetch}
          />
        ) : (
          <PaymentSection listRefetch={listRefetch} linkTabData={linkTabData}/>
        )}
      </div>
    </div>
  );
};

export default Verify;
