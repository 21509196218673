import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Campaign/Creation/CreationSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteUser } from "../../ContentVerification/api";

const useBlock = ({ refetch }) => {
  const dispatch = useDispatch();
  const { campaignUserId } = useSelector((state) => state.campaignCreate);
  const formik = useFormik({
    initialValues: { reason: "", ar_reason: "" },
    validate: (values) => {
      let errors = {};
      if (!values.reason) {
        errors.reason = "Reason Required";
      }
      if (!values.ar_reason) {
        errors.ar_reason = "Reason (سبب) Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        user_campaign_id: campaignUserId,
        reason: values?.reason,
        ar_reason: values?.ar_reason,
      };
      deleteUser(data).then((response) => {
        if (response?.data?.code === 200) {
          toast.success("Blocked");
          refetch();
          handleCloseModal()
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const handleCloseModal = () => {
    dispatch(
        updateConfig((state) => {
          state.detailsPage.blockModal = !state.detailsPage.blockModal;
        })
      );
  };
  return {
    formik,
    handleCloseModal,
  };
};

export default useBlock;
