import React from "react";
import Style from "./postItem.module.scss";
import Select from "react-select";
import usePostItem from "./usePostItem";
import { Input } from "@wac-ui-dashboard/wac_component_library";

const PostItem = ({
  formik,
  itmIndex,
  index,
  basicData,
  tempIds,
  getFieldError,
}) => {
  const { handleRemoveField } = usePostItem({
    formik,
  });
  return (
    <>
      <div
        className={`${Style.container} pro-d-flex pro-flex-column`}
        key={index}
      >
              <Input
                type="text"
                name="post_url"
                label={"Post URL"}
                className={`pro-input lg ${
                  formik.touched?.post?.[itmIndex]?.post_url &&
                  formik.errors?.post?.[itmIndex]?.post_url &&
                  " error"
                }`}
                {...formik.getFieldProps(`post.${itmIndex}.post_url`)}
                onBlur={formik.handleBlur(`post.${itmIndex}.post_url`)}
                value={formik?.values?.post?.[itmIndex]?.post_url}
                error={
                  formik.touched?.post?.[itmIndex]?.post_url &&
                  formik.errors?.post?.[itmIndex]?.post_url && (
                    <span className="error-text">
                      {formik.errors?.post?.[itmIndex]?.post_url}
                    </span>
                  )
                }
                errorMessage={
                  formik.touched?.post?.[itmIndex]?.post_url &&
                  formik.errors?.post?.[itmIndex]?.post_url && (
                      formik.errors?.post?.[itmIndex]?.post_url
                  )
                }
              />
            <div className="row gx-3">
              <div className="col">
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor="post_type"
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    Type
                  </label>
                  <Select
                    id="post_type"
                    placeholder={"Select"}
                    className={`pro-input lg  ${
                      formik.touched?.post?.[itmIndex]?.post_type &&
                      formik.errors?.post?.[itmIndex]?.post_type &&
                      "error"
                    }`}
                    classNamePrefix="pro-input"
                    name="post_type"
                    options={basicData?.asset_type}
                    getOptionValue={(option) => option?.id}
                    getOptionLabel={(option) => option?.name}
                    value={formik?.values?.post?.[itmIndex]?.post_type}
                    onBlur={formik.handleBlur(`post.${itmIndex}.post_type`)}
                    onChange={(value) =>
                      formik.setFieldValue(
                        `post.${itmIndex}.post_type`,
                        value || ""
                      )
                    }
                    menuPlacement="auto"
                    isClearable
                  />
                  {formik.touched?.post?.[itmIndex]?.post_type &&
                    formik.errors?.post?.[itmIndex]?.post_type && (
                      <span className="error-text">
                        {formik.errors?.post?.[itmIndex]?.post_type}
                      </span>
                    )}
                </div>
              </div>
              <div className="col">
                <Input
                  type="text"
                  name="like_count"
                  label={"Like count"}
                  className={`pro-input lg ${
                    formik.touched?.post?.[itmIndex]?.like_count &&
                    formik.errors?.post?.[itmIndex]?.like_count &&
                    " error"
                  }`}
                  {...formik.getFieldProps(`post.${itmIndex}.like_count`)}
                  onBlur={formik.handleBlur(`post.${itmIndex}.like_count`)}
                  value={formik?.values?.post?.[itmIndex]?.like_count}
                  error={
                    formik.touched?.post?.[itmIndex]?.like_count &&
                    formik.errors?.post?.[itmIndex]?.like_count && (
                      <span className="error-text">
                        {formik.errors?.post?.[itmIndex]?.like_count}
                      </span>
                    )
                  }
                  errorMessage={
                    formik.touched?.post?.[itmIndex]?.like_count &&
                    formik.errors?.post?.[itmIndex]?.like_count && (
                        formik.errors?.post?.[itmIndex]?.like_count
                    )
                  }
                />
              </div>
            </div>
            <div className="row gx-3">
                <div className="col">
                  <Input
                    type="text"
                    name="comment_count"
                    label={"Comment count"}
                    className={`pro-input lg ${
                      formik.touched?.post?.[itmIndex]?.comment_count &&
                      formik.errors?.post?.[itmIndex]?.comment_count &&
                      " error"
                    }`}
                    {...formik.getFieldProps(`post.${itmIndex}.comment_count`)}
                    onBlur={formik.handleBlur(`post.${itmIndex}.comment_count`)}
                    value={formik?.values?.post?.[itmIndex]?.comment_count}
                    error={
                      formik.touched?.post?.[itmIndex]?.comment_count &&
                      formik.errors?.post?.[itmIndex]?.comment_count && (
                        <span className="error-text">
                          {formik.errors?.post?.[itmIndex]?.comment_count}
                        </span>
                      )
                    }
                    errorMessage={
                      formik.touched?.post?.[itmIndex]?.comment_count &&
                      formik.errors?.post?.[itmIndex]?.comment_count && (
                          formik.errors?.post?.[itmIndex]?.comment_count
                      )
                    }
                  />
                </div>
                <div className="col">
                  <Input
                    type="text"
                    name="view_count"
                    label={"View count"}
                    className={`pro-input lg ${
                      formik.touched?.post?.[itmIndex]?.view_count &&
                      formik.errors?.post?.[itmIndex]?.view_count &&
                      " error"
                    }`}
                    {...formik.getFieldProps(`post.${itmIndex}.view_count`)}
                    onBlur={formik.handleBlur(`post.${itmIndex}.view_count`)}
                    value={formik?.values?.post?.[itmIndex]?.view_count}
                    error={
                      formik.touched?.post?.[itmIndex]?.view_count &&
                      formik.errors?.post?.[itmIndex]?.view_count && (
                        <span className="error-text">
                          {formik.errors?.post?.[itmIndex]?.view_count}
                        </span>
                      )
                    }
                    errorMessage={
                      formik.touched?.post?.[itmIndex]?.view_count &&
                      formik.errors?.post?.[itmIndex]?.view_count && (
                          formik.errors?.post?.[itmIndex]?.view_count
                      )
                    }
                  />
                </div>
              </div>
      </div>
      <div
          className={`pro-d-flex pro-justify-end pro-pt-3 `}
        >
          {formik?.values?.post?.length > 1 && (
            <span
            className="pro-pnt pro-text-underline"
              onClick={() => handleRemoveField(itmIndex)}
            >
              Remove
            </span>
          )}
        </div>
    </>
  );
};

export default PostItem;
