const useDo = ({ formik }) => {
  const handleRemoveField = (index) => {
    const data = formik?.values?.dos?.filter(
      (_, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("dos", data);
  };

  return {
    handleRemoveField,
  };
};

export default useDo;
