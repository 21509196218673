import React from "react";
import Assets from "../../../Assets";

const SocialIconSingle = ({ icon = "" }) => {
  switch (icon) {
    case "facebook":
      return (
        <div className="icon-wrap">
          <img
            src={Assets.FACEBOOK_ICON}
            alt={"facebook"}
            width={24}
            height={24}
          />
        </div>
      );
    case "instagram":
      return (
        <div className="icon-wrap">
          <img
            src={Assets.INSTAGRAM_ICON}
            alt={"instagram"}
            width={24}
            height={24}
          />
        </div>
      );
    case "youtube":
      return (
        <div className="icon-wrap">
          <img
            src={Assets.YOUTUBE_ICON}
            alt={"youtube"}
            width={24}
            height={24}
          />
        </div>
      );
    case "tiktok":
      return (
        <div className="icon-wrap">
          <img src={Assets.TIKTOK_ICON} alt={"tiktok"} width={24} height={24} />
        </div>
      );
    case "linkedin":
      return (
        <div className="icon-wrap">
          <img
            src={Assets.LINKEDIN_ICON}
            alt={"linkedin"}
            width={24}
            height={24}
          />
        </div>
      );
    case "google":
      return (
        <div className="icon-wrap">
          <img src={Assets.GOOGLE_ICON} alt={"google"} width={24} height={24} />
        </div>
      );
    default:
      break;
  }
};

export default SocialIconSingle;
