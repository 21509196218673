import Dropdown from "react-bootstrap/Dropdown";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";
import { OffCanvasLayout } from "@wac-ui-dashboard/wac_component_library";
import Profile from "./Profile";
import Notification from "../../../../Components/Global/Notification";

const HeaderActions = () => {
  const {
    globalState,
    notifications,
    isNewNotification,
    notificationsFetching,
    handleLogout,
    handleSettings,
    handleViewProfile,
    handleCloseProfile,
    handleNotificationRedirect,
    handleNotificationsRedirect,
  } = useHeaderActions();

  return (
    <div className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root}`}>
      <Notification
        data={notifications?.data?.data}
        isFetching={notificationsFetching}
        isNewNotification={isNewNotification}
        handleNotificationsRedirect={handleNotificationsRedirect}
        handleNotificationRedirect={handleNotificationRedirect}
      />
      <Dropdown>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar lg pro-flex-shrink-0">
            {!true ? (
              <img src={""} alt="staff_image" />
            ) : (
              <span
                className={`material-symbols-outlined x4 ${Style.profile_icon}`}
              >
                {" "}
                account_circle{" "}
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={handleViewProfile}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3">
              {" "}
              account_circle{" "}
            </span>
            <span className="pro-ms-2">Profile</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleSettings}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> settings </span>
            <span className="pro-ms-2">Settings</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleLogout}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> logout </span>
            <span className="pro-ms-2">Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
        <OffCanvasLayout
          show={globalState?.profileModal}
          handleClose={handleCloseProfile}
          title={"Profile"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <Profile />
        </OffCanvasLayout>
      </Dropdown>
    </div>
  );
};

export default HeaderActions;
