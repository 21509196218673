import { getAxiosInstance } from "../../../API";

export const statusChange = async (value) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/admin/support/status-change?support_id=${value?.support_id}&status=${value?.status}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const replay = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/support/reply`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
