import { Image } from "@wac-ui-dashboard/wac_component_library";
import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import Style from "./auth.module.scss";
import Assets from "../../Assets";
import { useSelector } from "react-redux";

const AuthModule = () => {
  const emailRef = useRef("");
  const globalState = useSelector((state) => state.global);
  return (
    <div className={Style.root}>
      <div className={Style.root_inner}>
        <div className={`${Style.logoWrapper} pro-pb-5`}>
          <Image
            src={
              globalState.currentTheme === "light" ||
              globalState.currentTheme === null
                ? Assets.LOGO
                : Assets.LOGO_INVERT
            }
            width={201.51}
            height={50.03}
            alt={`DHC Logo`}
            propStyle={{ objectFit: "cover" }}
          />
        </div>
        <div className={`${Style.auth} pro-pt-5`}>
          <Outlet context={{ emailRef }} />
        </div>
      </div>
    </div>
  );
};

export default AuthModule;
