import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetContentVerificationDataQuery,
  useUpdateConentVerificationTableHeadDataMutation,
} from "../../../Store/Queries/Campaign";
import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Campaign/ContentVerification/contentVerificationSlice";
import { updateConfig  as campaignCreateSlice } from "../../../Store/Slices/Campaign/Creation/CreationSlice";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import SocialIcons from "../../Global/SocialIcons";
import Style from "./contentVerification.module.scss";
import { IconText, Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../Assets";
import { getVerifyData } from "./api";
import { toast } from "react-toastify";

const useContentVerification = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const contentVerificationState = useSelector(
    (state) => state.contentVerification
  );

  const { showEditModal,currentUrl } = useSelector((state) => state.global);
  const [updateFields] = useUpdateConentVerificationTableHeadDataMutation();

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: contentVerificationData,
    isLoading,
    isFetching,
    refetch,
  } = useGetContentVerificationDataQuery({
    items_per_page: contentVerificationState.currentPageSize,
    current_page: contentVerificationState.currentPage,
    sort_by: contentVerificationState.sortBy,
    sort_order: contentVerificationState.sortOrder,
    search: contentVerificationState.search,
    status:
      contentVerificationState.activeTab === "all"
        ? 1
        : contentVerificationState?.activeTab === "pending"
        ? 2
        : contentVerificationState?.activeTab === "approved"
        ? 3
        : 4,
  });

  useEffect(() => {
    if (currentUrl?.includes("campaign-details")) {
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
        })
      );
      dispatch(
        globalUpdateConfig((state) => {
         state.currentUrl=""
        })
      );
    }else{
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
          state.currentPage = 1;
          state.currentPageSize = 10;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const navigation = [
    {
      label: "All",
      title: "All",
      link: `/campaign/content-verification?activeTab=all`,
      active: activeTab === "all",
    },
    {
      label: "Pending",
      title: "Pending",
      link: `/campaign/content-verification?activeTab=pending`,
      active: activeTab === "pending",
    },
    {
      label: "Approved",
      title: "Approved",
      link: `/campaign/content-verification?activeTab=approved`,
      active: activeTab === "approved",
    },
    {
      label: "Rejected",
      title: "Rejected",
      link: `/campaign/content-verification?activeTab=rejected`,
      active: activeTab === "rejected",
    },
  ];

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleSort = (label) => {
    if (contentVerificationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            contentVerificationState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleVerify = (data) => {
    const id = data?._id;
    const params = {
      user_campaign_id: id,
    };
    dispatch(
      updateConfig((state) => {
        state.verifyModal = true;
        state.selectedId = id;
      })
    );
    getVerifyData(params).then((response) => {
      if (response?.data?.code === 200) {
        dispatch(
          updateConfig((state) => (state.verifyData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleCloseVerifyModal = () => {
    dispatch(updateConfig((state) => (state.verifyModal = false)));
  };
  const handleClick=(data)=>{
    if(contentVerificationData?.data?.permissions?.can_view_user){
      navigate({ pathname: `/user-details/${data?.user_id}` })
    }else{
      return
    }
  }
  const handleClickBrand=(data)=>{
    if(contentVerificationData?.data?.permissions?.can_view_brand){
      navigate({ pathname: `/brand-details/${data?.brand_id}` })
    }else{
      return
    }
  }
  const hasBlockPermission = useMemo(() => {
    const permissions =contentVerificationData?.data?.permissions?? {};
    return permissions["can_block_user"] ?? "";
    //eslint-disable-next-line
  }, [contentVerificationData]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      social_media: (feild, data) => <SocialIcons icons={data[feild]} />,
      brand: (feild, data) => (
        <div className={`${Style.brand} ${contentVerificationData?.data?.permissions?.can_view_brand?"pro-pnt":""}`}  onClick={() => handleClickBrand(data)} >
          <div className="pro-avatar">
            <Image
              src={data["brand_thumb_image"] ?? Assets?.BRAND_ICON2}
              alt="brand-logo"
              width={22}
              height={22}
            />
          </div>
          <p className="pro-mb-0 pro-ms-2 no-wrap">{data[feild]}</p>
        </div>
      ),
      user_name: (feild, data) => (
        <div className={`${contentVerificationData?.data?.permissions?.can_view_user? "pro-pnt":""} text-truncate`}>
          <IconText
            icon={
              <img
                src={data["user_thumb_image"] ?? Assets.PROFILE_USER}
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            onClick={() => handleClick(data)}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      status: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            contentVerificationData?.data?.permissions?.can_change_status
              ? `pro-pnt text-truncate ${Style.name}`
              : ""
          }`}
          onClick={() =>
            contentVerificationData?.data?.permissions?.can_change_status
              ? handleVerify(data)
              : {}
          }
        >
          {data[feild]}
        </p>
      ),
      campaign_title: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            contentVerificationData?.data?.permissions?.can_view_campaign
              ? `pro-pnt text-truncate ${Style.name}`
              : ""
          }`}
          onClick={() => {
            if (contentVerificationData?.data?.permissions?.can_view_campaign) {
              dispatch(
                campaignCreateSlice((state) => {
                  state.detailsPage.currentPageSize = 10;
                  state.detailsPage.currentPage = 1;
                })
              );
              navigate({
                pathname: `/campaign-details/${data?.capmaign_id}activeTab="for-approval"&type="content-verification"&active="${activeTab}"`,
              });
              sessionStorage.setItem("active-campaign", `${data?.capmaign_id}`);
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    showEditModal,
    paginationOptions,
    tabNavs: navigation,
    contentVerificationData,
    contentVerificationState,
    hasBlockPermission,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    updateFields,
    handlePageSize,
    handleClearClick,
    handlePagination,
    handleEditColumnsClick,
    handleCloseVerifyModal,
  };
};

export default useContentVerification;
