import style from "./dashboardCardShimmer.module.scss";
const DashboardCardShimmer = () => {
  return (
    <div className="pro-w-100">
      <div className={`pro-p-4 ${style.shimmer_container}`}>
        <div className="pro-d-flex pro-mb-3 pro-gap-2">
          <div className="shimmer pro-px-4 pro-py-4 pro-rounded-3"></div>
          <div className="shimmer pro-w-100 letter para pro-mt-3"></div>
        </div>
        <div className="shimmer pro-px-5 pro-pb-5 pro-rounded-3"></div>
      </div>
    </div>
  );
};

export default DashboardCardShimmer;
