import React from "react";
// import { mergeClasses } from "../../Logic/mergeClasses";
// import DefaultStyle from "./simpleReportText.module.scss";
import Style from "./simplereport.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const SimpleReport = ({
  extraClassNames,
  data,
  showButton,
  buttonText,
  handleButtonClick,
  propStyle,
}) => {

  return (
    <div className={`${Style.root} ${extraClassNames}`}>
      <div className={Style.textWrapper}>
        <div className={`${Style.textInner} col-12`}>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="social-slider"
          >
            <SwiperSlide>
              <div className="row">
                {data?.slice(0, 3)?.map((item, index) => (
                  <div className={`${Style.item} col`} key={index}>
                    <p className="pro-fw-medium pro-mb-1">{item?.label}</p>
                    <h5 className="pro-ttl h5 pro-mb-0">{item?.value}</h5>
                  </div>
                ))}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="row">
                {data?.slice(3)?.map((item, index) => (
                  <div className={`${Style.item} col`} key={index}>
                    <p className="pro-fw-medium pro-mb-1">{item?.label}</p>
                    <h5 className="pro-ttl h5 pro-mb-0">{item?.value}</h5>
                  </div>
                ))}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {showButton && (
        <div className={Style.actionWrapper}>
          <button
            onClick={handleButtonClick}
            className="pro-btn pro-btn-outline"
          >
            <span>{buttonText}</span>
          </button>
        </div>
      )}
    </div>
  );
};
export default SimpleReport;
