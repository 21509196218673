import { Navigate } from "react-router-dom";
import useProtectRoute from "./useProtectRoute";

const ProtectRoute = ({ children }) => {
  const { state } = useProtectRoute();
  let auth = localStorage.getItem("USER_ACCESS_TOKEN_INVESTO");
  if (!auth) {
    return <Navigate to={"/login"} />;
  } else if (state.menu_permissions_status !== "fulfilled") {
    return <div className="app-loading fill"></div>;
  }
  return children;
};

export default ProtectRoute;
