import { getAxiosInstance } from "../../../../API";

export const socialConnectionCreate = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/social-media-account/create", body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const viewSocialConnectionItem = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/social-media-account/view", body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateSocialConnection = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/social-media-account/update", body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteSocialConnection = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/social-media-account/remove", body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
