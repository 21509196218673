import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editModal: false,
  desciption: "",
  ar_description: "",
};

const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = termsSlice.actions;
export default termsSlice.reducer;
