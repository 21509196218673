import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Global";

const useDrawerTheme = (initialTheme) => {
  const dispatch = useDispatch();
  let themeOptions = [
    "light",
    "dark",
    "black",
    "compact",
    "detailed",
    "expanded",
    "closed",
  ];
  const storedTheme = localStorage.getItem("drawerTheme");
  const [theme, setTheme] = useState(storedTheme || initialTheme);

  const handleDrawerThemeChange = (selectedTheme) => {
    setTheme(selectedTheme);
    localStorage.setItem("drawerTheme", selectedTheme);
  };

  let drawerTheme = localStorage.getItem("drawerTheme");

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.drawerTheme = drawerTheme;
      })
    );
    // eslint-disable-next-line
  }, [drawerTheme]);

  useEffect(() => {
    localStorage.setItem("drawerTheme", theme);
    document.body.classList.remove(...themeOptions);
    document.body.classList.add(theme);
    document.body.setAttribute("data-drawer-theme", theme);
    // eslint-disable-next-line
  }, [theme]);

  return { sideMenu: theme, handleDrawerThemeChange, setTheme };
};

export default useDrawerTheme;
