import Style from "./notificationListingShimmer.module.scss";

const NotificationListingShimmer = () => {
  return (
    <div className={`pro-d-flex pro-gap-3 ${Style.root}`}>
      <div className={`${Style.image} blink pro-flex-shrink-0`}></div>
      <div className="pro-d-flex pro-flex-column pro-align-self-start pro-w-100">
        <div className={`shimmer pro-py-1 ${Style.text_1} pro-mb-2`}></div>
        <div className={`shimmer pro-py-1 ${Style.text_2}`}></div>
      </div>
      <div className="pro-ps-6 pro-pe-5 pro-py-1 pro-align-self-start shimmer"></div>
    </div>
  );
};

export default NotificationListingShimmer;
