import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  activeTab: "all",
  verifyModal: false,
  verifyData: "",
  selectedId: "",
  rejectBox1: false,
  rejectBox2: false,
};

const contentVerificationSlice = createSlice({
  name: "contentVerification",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = contentVerificationSlice.actions;
export default contentVerificationSlice.reducer;
