import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Campaign/Creation/CreationSlice";
// import { useEffect } from "react";
import { useSelector } from "react-redux";
import { campaignCreateDelete, campaignCreateUpload } from "../api";
import { toast } from "react-toastify";

const useUploads = ({ refetch, formik, handleMoveToErrorTab }) => {
  const dispatch = useDispatch();
  const {
    values,
    errors,
    // isEdit,
    campainId,
    // selectedData,
    uploadStatus,
    bannerPreview,
    isLiveEdit,
  } = useSelector((state) => state.campaignCreate);

  // useEffect(() => {
  //   if (isEdit && selectedData) {
  //     const assetsImages = selectedData?.assets?.images?.map((value) => ({
  //       file: value?.file_name,
  //       thumb: value?.thumbnail,
  //     }));

  //     const assetsVideos = selectedData?.assets?.videos?.map((value) => ({
  //       file: value?.file_name,
  //       thumb: value?.thumbnail,
  //     }));

  //     const refImages = selectedData?.reference_assets?.images?.map(
  //       (value) => ({
  //         file: value?.file_name,
  //         thumb: value?.thumbnail,
  //       })
  //     );

  //     const refVideos = selectedData?.reference_assets?.videos?.map(
  //       (value) => ({
  //         file: value?.file_name,
  //         thumb: value?.thumbnail,
  //       })
  //     );

  //     dispatch(
  //       updateConfig((state) => {
  //         state.values.banner.file = selectedData?.image ?? "";
  //         state.values.banner.thumb = selectedData?.thumbnail_image ?? "";
  //         state.values.assetsImages = assetsImages ?? [
  //           {
  //             file: "",
  //             thumb: "",
  //           },
  //         ];
  //         state.values.assetsVideos = assetsVideos ?? [
  //           {
  //             file: "",
  //             thumb: "",
  //           },
  //         ];
  //         state.values.refImages = refImages ?? [
  //           {
  //             file: "",
  //             thumb: "",
  //           },
  //         ];
  //         state.values.refVideos = refVideos ?? [
  //           {
  //             file: "",
  //             thumb: "",
  //           },
  //         ];
  //       })
  //     );
  //     const reduxTimeout = setTimeout(() => {
  //       dispatch(updateConfig((state) => (state.selectedData = "")));
  //       clearTimeout(reduxTimeout);
  //     }, 500);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isEdit, selectedData]);

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));

    //here
  };

  const handleBannerChange = (event) => {
    const selectedBanner = event?.target?.files[0];
    if (selectedBanner) {
      if (selectedBanner.type.startsWith("image/")) {
        if (selectedBanner.size <= 5 * 1024 * 1024) {
          dispatch(
            updateConfig((state) => (state.uploadStatus.banner = "pending"))
          );
          let data = new FormData();
          data.append("campaign_id", campainId);
          data.append("category", "banner");
          data.append("file_type", "image");
          data.append("file[1]", selectedBanner);
          campaignCreateUpload(data).then((response) => {
            if (response?.data?.code === 200) {
              dispatch(
                updateConfig((state) => {
                  state.uploadStatus.banner = "completed";
                  state.values.banner.file = response?.data?.data?.file_name;
                  state.values.banner.thumb = response?.data?.data?.thumbnail;
                  state.errors.banner = "";
                })
              );
            } else if (response?.code === 422) {
              dispatch(
                updateConfig((state) => {
                  state.errors.banner = response?.errors?.file;
                  state.uploadStatus.banner = "failed";
                })
              );
            } else {
              dispatch(
                updateConfig((state) => (state.uploadStatus.banner = "failed"))
              );
              toast.error("Something went wrong");
            }
          });
        } else {
          dispatch(
            updateConfig(
              (state) =>
                (state.errors.banner = "File size should be less than 5MB")
            )
          );
        }
      } else {
        dispatch(
          updateConfig((state) => (state.errors.banner = "Invalid File Type"))
        );
      }
    }
  };

  const handleBannerDelete = () => {
    let params = {
      campaign_id: campainId,
      category: "banner",
      file_type: "image",
      file_url: values?.banner.file,
    };
    campaignCreateDelete(params)
      .then((response) => {
        if (response?.data?.code === 200) {
          dispatch(
            updateConfig((state) => {
              state.values.banner.file = "";
              state.values.banner.thumb = "";
            })
          );
        }
      })
      .catch((error) => toast.error(error));
  };

  const handleAssetsImagesChange = (e) => {
    dispatch(
      updateConfig((state) => {
        state.errors.assetsImages = "";
        state.uploadStatus.assetsImages = "pending";
      })
    );
    const files = e?.target?.files;
    if (files?.length > 0) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      const errors = [];
      let validFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (!allowedTypes.includes(file.type)) {
          errors.push(`File ${file.name} is not a valid image type`);
        } else if (file.size > maxSize) {
          errors.push(`File ${file.name} exceeds the maximum size of 5MB`);
        } else {
          validFiles.push(file);
        }
      }

      if (errors.length > 0) {
        dispatch(
          updateConfig((state) => {
            state.errors.assetsImages = errors?.[0];
            state.uploadStatus.assetsImages = "failed";
          })
        );
      } else {
        dispatch(
          updateConfig((state) => {
            state.errors.assetsImages = "";
          })
        );
        let data = new FormData();
        data.append("campaign_id", campainId);
        data.append("category", "assets");
        data.append("file_type", "image");
        for (let i = 0; i < validFiles.length; i++) {
          const file = validFiles[i];
          data.append(`file[${i + 1}]`, file);
        }
        campaignCreateUpload(data).then((response) => {
          if (response?.data?.code === 200) {
            const newImages = response?.data?.data?.map((value) => ({
              file: value?.file_name,
              thumb: value?.thumbnail,
            }));  
            dispatch(
              updateConfig((state) => {
                state.errors.assetsImages = "";
                state.values.assetsImages = newImages;
                state.uploadStatus.assetsImages = "completed";
              })
            );
          } else if (response?.code === 422) {
            dispatch(
              updateConfig((state) => {
                state.errors.assetsImages = response?.errors?.file;
                state.uploadStatus.assetsImages = "failed";
              })
            );
          } else {
            toast.error("Something went wrong");
            dispatch(
              updateConfig(
                (state) => (state.uploadStatus.assetsImages = "failed")
              )
            );
          }
        });
      }
    }
  };

  const handleAssetsImagesDelete = async (data) => {
    const file = values?.assetsImages?.filter(
      (value) => value?.thumb === data?.thumb
    );
    let params = {
      campaign_id: campainId,
      category: "assets",
      file_type: "image",
      file_url: file?.[0]?.file ?? "",
    };
    campaignCreateDelete(params).then((response) => {
      if (response?.data?.code === 200) {
        const newImages = values?.assetsImages?.filter(
          (value) => value?.file !== file?.[0]?.file
        );
        dispatch(
          updateConfig((state) => {
            state.values.assetsImages = newImages;
          })
        );
      } else if (response?.code === 422) {
        dispatch(
          updateConfig((state) => {
            state.errors.assetsImages = response?.errors?.file;
          })
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const dataURLtoBlob = async (dataURL) => {
    var arr = dataURL.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleAssetsVideosChange = async (e) => {
    const files = e?.target?.files;
    if (files?.length > 0 && files?.length <= 3) {
      dispatch(
        updateConfig((state) => {
          state.errors.assetsVideos = "";
          state.uploadStatus.assetsVideos = "pending";
        })
      );
      if (files?.length > 0) {
        const allowedFormats = ["video/mp4", "video/mpeg", "video/quicktime"]; // Add more video formats as needed
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB limit
        const errors = [];
        let validFiles = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          if (!allowedFormats.includes(file.type)) {
            errors.push(`File ${file.name} is not a valid video format`);
          } else if (file.size > maxSizeInBytes) {
            errors.push(`File ${file.name} exceeds the 10 MB limit`);
          } else {
            validFiles.push(file);
          }
        }
        if (errors.length > 0) {
          dispatch(
            updateConfig((state) => {
              state.errors.assetsVideos = errors?.[0];
              state.uploadStatus.assetsVideos = "failed";
            })
          );
        } else {
          dispatch(
            updateConfig((state) => {
              state.errors.assetsVideos = "";
            })
          );

          let data = new FormData();
          data?.append("campaign_id", campainId);
          data?.append("category", "assets");
          data?.append("file_type", "video");
          for (let i = 0; i < validFiles.length; i++) {
            const file = validFiles[i];
            data.append(`file[${i + 1}]`, file);

            const thumbnailFile = await generateThumbnail(file, i + 1);
            data.append(`thumbnail[${i + 1}]`, thumbnailFile);
          }

          // Function to generate thumbnail using async/await
          async function generateThumbnail(file, index) {
            return new Promise((resolve) => {
              const video = document.createElement("video");
              video.src = URL.createObjectURL(file);
              video.onloadeddata = () => {
                const canvas = document.createElement("canvas");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext("2d");
                // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                video.currentTime = 2; // Set the time to 1 second (adjust if needed)
                video.play().then(async () => {
                  video.pause();
                  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                  // Here, you can use imlet gData or perform further actions with the second frame
                  let blob = await dataURLtoBlob(canvas.toDataURL());
                  const thumbnailFile = new File(
                    [blob],
                    `thumbnail_${index}.jpg`,
                    {
                      type: "image/jpeg",
                    }
                  );
                  resolve(thumbnailFile);
                });
              };
              video.src = URL.createObjectURL(file);
              video.load();
            });
          }
          campaignCreateUpload(data).then((response) => {
            if (response?.data?.code === 200) {
              const updatedResponse = response?.data?.data?.map((value) => {
                return {
                  file: value?.file_name,
                  thumb: value?.thumbnail,
                };
              });
              dispatch(
                updateConfig((state) => {
                  state.values.assetsVideos = updatedResponse;
                  state.uploadStatus.assetsVideos = "success";
                })
              );
            } else if (response?.code === 422) {
              dispatch(
                updateConfig((state) => {
                  state.errors.assetsVideos = response?.errors?.file;
                  state.uploadStatus.assetsVideos = "failed";
                })
              );
            } else {
              toast.error("Something went wrong");
              dispatch(
                updateConfig(
                  (state) => (state.uploadStatus.assetsVideos = "failed")
                )
              );
            }
          });
        }
      }
    } else {
      dispatch(
        updateConfig((state) => {
          state.errors.assetsVideos = "Please select up to 3 videos";
          state.uploadStatus.assetsVideos = "failed";
        })
      );
    }
  };

  const handleAssetsVideosDelete = (data) => {
    const file = values?.assetsVideos?.filter(
      (value) => value?.thumb === data?.thumb
    );
    let params = {
      campaign_id: campainId,
      category: "assets",
      file_type: "video",
      file_url: file?.[0]?.file ?? "",
    };

    campaignCreateDelete(params).then((response) => {
      if (response?.data?.code === 200) {
        const updatedAssestsVideos = values?.assetsVideos?.filter(
          (value) => file?.[0]?.file !== value?.file
        );
        dispatch(
          updateConfig((state) => {
            state.values.assetsVideos = updatedAssestsVideos;
            state.uploadStatus.assetsVideos = "success";
          })
        );
      } else {
        toast.error("Something went wrong");
        dispatch(
          updateConfig((state) => (state.uploadStatus.assetsVideos = "failed"))
        );
      }
    });
  };

  const handleRefImagesChange = (e) => {
    dispatch(
      updateConfig((state) => {
        state.errors.refImages = "";
        state.uploadStatus.refImages = "pending";
      })
    );
    const files = e?.target?.files;
    if (files?.length > 0) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      const errors = [];
      let validFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (!allowedTypes.includes(file.type)) {
          errors.push(`File ${file.name} is not a valid image type`);
        } else if (file.size > maxSize) {
          errors.push(`File ${file.name} exceeds the maximum size of 5MB`);
        } else {
          validFiles.push(file);
        }
      }

      if (errors.length > 0) {
        dispatch(
          updateConfig((state) => {
            state.errors.refImages = errors?.[0];
            state.uploadStatus.refImages = "failed";
          })
        );
      } else {
        dispatch(
          updateConfig((state) => {
            state.errors.refImages = "";
          })
        );
        let data = new FormData();
        data.append("campaign_id", campainId);
        data.append("category", "reference_assets");
        data.append("file_type", "image");
        for (let i = 0; i < validFiles.length; i++) {
          const file = validFiles[i];
          data.append(`file[${i + 1}]`, file);
        }
        campaignCreateUpload(data).then((response) => {
          if (response?.data?.code === 200) {
            const newImages = response?.data?.data?.map((value) => ({
              file: value?.file_name,
              thumb: value?.thumbnail,
            }));
            dispatch(
              updateConfig((state) => {
                state.errors.refImages = "";
                state.values.refImages = newImages;
                state.uploadStatus.refImages = "completed";
              })
            );
          } else if (response?.code === 422) {
            dispatch(
              updateConfig((state) => {
                state.errors.refImages = response?.errors?.file;
                state.uploadStatus.refImages = "failed";
              })
            );
          } else {
            toast.error("Something went wrong");
            dispatch(
              updateConfig((state) => (state.uploadStatus.refImages = "failed"))
            );
          }
        });
      }
    }
  };

  const handleRefImagesDelete = (data) => {
    const file = values?.refImages?.filter(
      (value) => value?.thumb === data?.thumb
    );
    let params = {
      campaign_id: campainId,
      category: "reference_assets",
      file_type: "image",
      file_url: file?.[0]?.file ?? "",
    };
    campaignCreateDelete(params).then((response) => {
      if (response?.data?.code === 200) {
        const newImages = values?.refImages?.filter(
          (value) => value?.file !== file?.[0]?.file
        );
        dispatch(
          updateConfig((state) => {
            state.values.refImages = newImages;
          })
        );
      } else if (response?.code === 422) {
        dispatch(
          updateConfig((state) => {
            state.errors.refImages = response?.errors?.file;
          })
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleRefVideosChange = async (e) => {
    const files = e?.target?.files;
    if (files?.length > 0 && files?.length <= 3) {
      dispatch(
        updateConfig((state) => {
          state.errors.refVideos = "";
          state.uploadStatus.refVideos = "pending";
        })
      );
      if (files?.length > 0) {
        const allowedFormats = ["video/mp4", "video/mpeg", "video/quicktime"]; // Add more video formats as needed
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB limit
        const errors = [];
        let validFiles = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          if (!allowedFormats.includes(file.type)) {
            errors.push(`File ${file.name} is not a valid video format`);
          } else if (file.size > maxSizeInBytes) {
            errors.push(`File ${file.name} exceeds the 10 MB limit`);
          } else {
            validFiles.push(file);
          }
        }
        if (errors.length > 0) {
          dispatch(
            updateConfig((state) => {
              state.errors.refVideos = errors?.[0];
              state.uploadStatus.refVideos = "failed";
            })
          );
        } else {
          dispatch(
            updateConfig((state) => {
              state.errors.refVideos = "";
            })
          );

          let data = new FormData();
          data?.append("campaign_id", campainId);
          data?.append("category", "reference_assets");
          data?.append("file_type", "video");
          for (let i = 0; i < validFiles.length; i++) {
            const file = validFiles[i];
            data.append(`file[${i + 1}]`, file);

            const thumbnailFile = await generateThumbnail(file, i + 1);
            data.append(`thumbnail[${i + 1}]`, thumbnailFile);
          }

          // Function to generate thumbnail using async/await
          async function generateThumbnail(file, index) {
            return new Promise((resolve) => {
              const video = document.createElement("video");
              video.src = URL.createObjectURL(file);
              video.onloadeddata = () => {
                const canvas = document.createElement("canvas");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext("2d");
                // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                video.currentTime = 2; // Set the time to 1 second (adjust if needed)
                video.play().then(async () => {
                  video.pause();
                  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                  // Here, you can use imlet gData or perform further actions with the second frame
                  let blob = await dataURLtoBlob(canvas.toDataURL());
                  const thumbnailFile = new File(
                    [blob],
                    `thumbnail_${index}.jpg`,
                    {
                      type: "image/jpeg",
                    }
                  );
                  resolve(thumbnailFile);
                });
              };
              video.src = URL.createObjectURL(file);
              video.load();
            });
          }
          campaignCreateUpload(data).then((response) => {
            if (response?.data?.code === 200) {
              const updatedResponse = response?.data?.data?.map((value) => {
                return {
                  file: value?.file_name,
                  thumb: value?.thumbnail,
                };
              });
              dispatch(
                updateConfig((state) => {
                  state.values.refVideos = updatedResponse;
                  state.uploadStatus.refVideos = "completed";
                })
              );
            } else if (response?.code === 422) {
              dispatch(
                updateConfig((state) => {
                  state.errors.refVideos = response?.errors?.file;
                  state.uploadStatus.refVideos = "failed";
                })
              );
            } else {
              toast.error("Something went wrong");
              dispatch(
                updateConfig(
                  (state) => (state.uploadStatus.refVideos = "failed")
                )
              );
            }
          });
        }
      }
    } else {
      dispatch(
        updateConfig((state) => {
          state.errors.refVideos = "Please select up to 3 videos";
          state.uploadStatus.refVideos = "failed";
        })
      );
    }
  };

  const handleRefVideosDelete = (data) => {
    const file = values?.refVideos?.filter(
      (value) => value?.thumb === data?.thumb
    );
    let params = {
      campaign_id: campainId,
      category: "reference_assets",
      file_type: "video",
      file_url: file?.[0]?.file ?? "",
    };

    campaignCreateDelete(params).then((response) => {
      if (response?.data?.code === 200) {
        const updatedRefVideos = values?.refVideos?.filter(
          (value) => file?.[0]?.file !== value?.file
        );
        dispatch(
          updateConfig((state) => {
            state.values.refVideos = updatedRefVideos;
            state.uploadStatus.refVideos = "success";
          })
        );
      } else {
        toast.error("Something went wrong");
        dispatch(
          updateConfig((state) => (state.uploadStatus.refVideos = "success"))
        );
      }
    });
  };

  const handleSubmit = () => {
    refetch();
    dispatch(updateConfig((state) => (state.createModal = false)));
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    );
    formik?.values?.platform.forEach((_, index) => {
      formik.setFieldTouched(`platform[${index}].p_name`, true);
      formik.setFieldTouched(`platform[${index}].p_type`, true);
      formik.setFieldTouched(`platform[${index}].m_type`, true);
    });
    if (
      formik?.values?.platform?.some(
        (item) => item?.p_name?.name?.toLowerCase() === "google"
      )
    ) {
      formik?.setFieldTouched("maps");
    }
    handleMoveToErrorTab();
    formik?.handleSubmit();
  };

  return {
    values,
    errors,
    uploadStatus,
    bannerPreview,
    isLiveEdit,
    handleSubmit,
    handleCloseModal,
    handleBannerDelete,
    handleBannerChange,
    handleRefVideosChange,
    handleRefVideosDelete,
    handleRefImagesChange,
    handleRefImagesDelete,
    handleAssetsImagesChange,
    handleAssetsImagesDelete,
    handleAssetsVideosChange,
    handleAssetsVideosDelete,
  };
};

export default useUploads;
