import React from "react";
import useLinkSection from "./useLinkSection";
import { Button, Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "./linkSection.module.scss";

const LinkSection = ({ linkTabData, refetch, listRefetch }) => {
  const {
    remark,
    errors,
    linkData,
    isFetching,
    ar_remark,
    handleArabicRemarkChange,
    handleApproveClick,
    handleRejectClick,
    handleCopyLink,
    handleModalClose,
    handleRemarkChange,
  } = useLinkSection({ refetch, listRefetch });

  return (
    <div className="pro-h-100 pro-d-flex pro-flex-column">
      {isFetching ? (
        <p>loading</p>
      ) : (
        <>
          <div className="pro-ps-5">
            {linkData?.data?.data?.social_media?.map((value, index) => {
              return (
                <div
                  key={index}
                  className={`${Style.platform_root} pro-pb-5 pro-mb-5`}
                >
                  <div className="pro-d-flex pro-justify-between pro-items-center">
                    <h6 className="pro-ttl h6 pro-mb-4">
                      {value?.stream?.[0]?.toUpperCase() +
                        value?.stream?.slice(1)}
                    </h6>
                    <p className="pro-mb-4 ">
                      Allowed Amount : <strong>{value?.allowed_amount}</strong>
                    </p>
                  </div>
                  {value?.attachment?.map((val, attachIndex) => {
                    return (
                      <div
                        className={`pro-d-flex  pro-justify-between pro-p-4 pro-mb-4 pro-gap-4 ${Style.social_dtls}`}
                        key={attachIndex}
                      >
                        <div className={` ${Style.dtl_col} ${Style.col_label}`}>
                          <p className="pro-mb-2 pro-font-sm pro-fw-medium">
                            {val?.content_type?.[0]?.toUpperCase() +
                              val?.content_type?.slice(1)}
                          </p>
                          {val?.url_or_file === 2 && (
                            <div className="pro-mb-1">
                              <Image src={val?.thumbnail} />
                            </div>
                          )}

                          <p className="pro-mb-0 pro-font-sm pro-fw-medium pro-mt-3">
                            Updated on{" "}
                            {val?.url_or_file === 2 && (
                              <span
                                className={`pro-mx-4 pro-fw-normal  ${Style.submission_date}`}
                              >
                                {val?.submission_date}
                              </span>
                            )}
                          </p>
                        </div>
                        {val?.url_or_file === 1 && (
                          <>
                            <div
                              className={` ${Style.dtl_col} ${Style.col_info}`}
                            >
                              {val?.url_or_file === 2 ? (
                                <div>
                                  <Image src={val?.thumbnail} />
                                </div>
                              ) : (
                                <p className="pro-mb-0 pro-pnt">
                                  <a
                                  className="pro-btn-link"
                                    href={val?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {val?.url}
                                  </a>
                                </p>
                              )}
                              <p className="pro-mb-0 pro-mt-3">
                                {val?.submission_date}
                              </p>
                            </div>
                            <div
                              className={` ${Style.dtl_col} ${Style.col_action}`}
                            >
                              {val?.url_or_file === 1 && (
                                <span className="pro-p-1">
                                  <span
                                    onClick={() => handleCopyLink(val?.url)}
                                    className="material-symbols-outlined"
                                  >
                                    content_copy
                                  </span>
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div className="input-wrap">
              <label className="pro-font-sm pro-mb-1 pro-fw-medium">
                Remark*
              </label>
              <textarea
                onChange={handleRemarkChange}
                className={`pro-input lg ${errors && "error"}`}
                value={remark}
                rows={4}
                disabled={linkTabData?.data?.[0]?.status === "completed"}
              />
              {errors && <span className="error-text">{errors}</span>}
            </div>

            <div className="input-wrap pro-rtl">
              <label className="pro-font-sm pro-mb-1 pro-fw-medium">
                Remark (ملاحظة)*
              </label>
              <textarea
                onChange={handleArabicRemarkChange}
                // className={`pro-input lg ${errors && "error"}`}
                className={`pro-input lg`}
                value={ar_remark}
                rows={4}
                disabled={linkTabData?.data?.[0]?.status === "completed"}
              />
              {/* {errors && <span className="error-text">{errors}</span>} */}
            </div>
          </div>
          <div
            className={`col-12 pro-d-flex pro-justify-end pro-pt-4 pro-mb-5 pro-mt-auto pro-border-top`}
          >
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={handleModalClose}
              type="button"
            >
              Cancel
            </Button>
            <Button
              className={"pro-btn-primary lg pro-ms-3"}
              onClick={handleRejectClick}
              type="button"
              disabled={linkTabData?.data?.[0]?.status === "completed"}
            >
              Decline
            </Button>

            <Button
              className={"pro-btn-primary lg pro-ms-3"}
              onClick={handleApproveClick}
              type="button"
              disabled={linkTabData?.data?.[0]?.status === "completed"}
            >
              Approve
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default LinkSection;
