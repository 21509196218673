import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Assets from "../../../../Assets";
import Select from "react-select";
import useSentMessage from "./useSentMessage";

const SentMessage = ({ refetch }) => {
  const { formik, messageData, handleCloseModal, getFieldError } =
    useSentMessage({ refetch });
  return (
    <div className="row pro-m-3 ">
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="messageList"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Select your message
          </label>
          <Select
            id="messageList"
            placeholder={"Select your message"}
            className={`pro-input lg`}
            classNamePrefix="pro-input"
            name="messageList"
            options={messageData ?? []}
            getOptionValue={(option) => option?._id}
            getOptionLabel={(option) => option?.title}
            value={formik?.values?.messageList}
            onBlur={formik.handleBlur("messageList")}
            onChange={(value) => {
              formik.setFieldValue("messageList", value ?? "");
              formik.setFieldValue("message", value?.message ?? "");
              formik.setFieldValue("ar_message", value?.ar_message ?? "");
            }}
            menuPlacement="auto"
            isClearable
          />
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="message"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Message
          </label>
          <textarea
            id="message"
            rows={3}
            name="message"
            className={`pro-input lg ${getFieldError("message") && "error"} `}
            value={formik?.values?.message ?? ""}
            onBlur={formik?.handleBlur("message")}
            onChange={(e) => formik.setFieldValue("message", e.target.value)}
          ></textarea>
          {getFieldError("message") && (
            <span className="error-text">{getFieldError("message")}</span>
          )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label
            htmlFor="ar_message"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            (رسالة)Message
          </label>
          <textarea
            id="ar_message"
            rows={3}
            name="ar_message"
            className={`pro-input lg ${
              getFieldError("ar_message") && "error"
            } `}
            value={formik?.values?.ar_message ?? ""}
            onBlur={formik?.handleBlur("ar_message")}
            onChange={(e) => formik.setFieldValue("ar_message", e.target.value)}
          ></textarea>
          {getFieldError("ar_message") && (
            <span className="error-text">{getFieldError("ar_message")}</span>
          )}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={
            "pro-btn-primary lg pro-ms-3 pro-d-flex pro-align-center pro-gap-2"
          }
          onClick={formik.handleSubmit}
          disabled={formik?.isSubmitting}
        >
          {formik?.isSubmitting ? (
            <span className="btn-spinner">
              <img src={Assets.SPINNER} alt="spinner" />
            </span>
          ) : (
            "Add"
          )}
        </Button>
      </div>
    </div>
  );
};

export default SentMessage;
